import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'

class Firebase {
  static _app = null
  static _analytics = null
  static _messaging = null

  /**
   * Returns a shared analytics instance
   *
   * @returns {import('firebase/app').FirebaseApp} Firebase shared instance
   */
  static async _initApp() {
    if (!this._app) {
      const config = require('./firebase-config.json')
      this._app = initializeApp(config)
    }
    return this._app
  }

  /**
   * Returns a shared analytics instance
   *
   * @returns {import('firebase/app').FirebaseApp} Firebase shared instance
   */
  static get app() {
    return this._app
  }

  static get messaging() {
    if (!this._messaging) this._messaging = getMessaging(this.app)
    return this._messaging
  }

  static async _initAnalytics() {
    if (!this._analytics) {
      this._analytics = getAnalytics(this._app)
    }
  }

  static async _initRemoteConfig() {
    if (!this._remoteConfig) {
      this._remoteConfig = getRemoteConfig(this._app)
      fetchAndActivate(this._remoteConfig)
    }
  }

  /**
   * Returns a shared analytics instance
   *
   * @returns {import('firebase/analytics').Analytics} Firebase shared instance
   */
  static get analytics() {
    return this._analytics
  }

  /**
   * Returns a shared remote config instance
   *
   * @returns {import('firebase/remote-config').RemoteConfig} Firebase shared instance
   */
  static get remoteConfig() {
    return this._remoteConfig
  }

  static setUser(user) {
    setUserId(this.analytics, user.id)
    setUserProperties(this.analytics, {
      username: user.username,
      user_type: user.user_type,
    })
  }

  static async init() {
    await this._initApp()
    await this._initAnalytics()
    await this._initRemoteConfig()
  }

  static async logEvent(eventName, eventParams = {}) {
    logEvent(this.analytics, eventName, eventParams)
  }

  static getRemoteConfig(key) {
    return getValue(this._remoteConfig, key)
  }
}

export default Firebase
