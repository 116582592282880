const djangoModels = [
  'permission',
  'group',
  'content-type',
  'log-entry',
  'session',
  'token',
  'preven-log-entry',
  'preven-user',
  'access-profile',
  'measurement-unit',
  'database-import',
  'company',
  'company-backoffice',
  'employee',
  'employee-expense',
  'shift',
  'non-working-day',
  'expense-center',
  'sector',
  'sector-stopped-expense-log',
  'warehouse',
  'item-family',
  'item-category',
  'item',
  'providercategory',
  'provider',
  'maintenance',
  'urgency-level',
  'maintenance-provider',
  'maintenance-nature',
  'maintenance-item',
  'maintenance-employee',
  'delay-reason',
  'conservation-state',
  'maintenance-order',
  'maintenance-order-employee',
  'maintenance-order-provider',
  'maintenance-order-item',
  'maintenance-order-employee-attendance',
  'maintenance-status',
  'equipment',
  'equipment-group',
  'equipment-cost',
  'manufacture-item',
  'service-solicitation-access-token',
  'telemetry-device',
]

export default djangoModels
