export const validateFieldValue = (fieldData, value) => {
  if (fieldData.required)
    if (!value) return [false, fieldData.error_messages.required]
  return [true, '']
}

export const parseJson = (value) => {
  if (value)
    try {
      return JSON.parse(value)
    } catch (error) {}
  return null
}

export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.svg']
