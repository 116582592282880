import CIcon from '@coreui/icons-react'
import { CAlert, CButton, CCard, CDataTable } from '@coreui/react'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModelApi } from 'src/api'
import DocumentCard from 'src/components/DocumentCard'
import { authSelectors } from 'src/state/ducks/auth'
import { modalOperations } from 'src/state/ducks/modal'
import { ActionButton } from 'src/views/buttons'
import { EmptyTableSlot } from '../DjangoList'
import { formattedDate, statusColumn } from '../DjangoListColumnParsers'
import GenericInputGroup from './GenericInputGroup'
import { parseJson } from './utils'

export const EmbeddedFormInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onRelationChange,
  submited,
  objectId,
  djangoModel,
}) => {
  const token = useSelector(authSelectors.token)
  const dispatch = useDispatch()
  const showModal = useCallback(
    (...params) => dispatch(modalOperations.show(...params)),
    [dispatch]
  )
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState(null)

  const modelFieldName = ModelApi.getFieldNameFromDjangoModel(djangoModel)

  const { widget } = fieldData

  const filters = {}
  if (widget.attrs.filters)
    Object.assign(filters, parseJson(widget.attrs.filters) || {})

  if (!objectId)
    return (
      <GenericInputGroup
        fieldName={fieldName}
        fieldData={fieldData || {}}
        fieldErrors={fieldErrors}
        value={value}
        submited={submited}
      >
        <CAlert color="info">
          <CIcon name="cil-info" />
          &nbsp;Salve o registro para poder vincular {fieldData.label}
        </CAlert>
      </GenericInputGroup>
    )

  if (!listData && !loading && objectId) {
    setLoading(true)
    ModelApi.shared
      .list(
        token,
        fieldData.app_name,
        fieldData.model_name,
        {
          ...filters,
          [`${modelFieldName}_id`]: objectId,
        },
        undefined,
        999,
        undefined,
        undefined,
        false
      )
      .then(
        ({ data }) => {
          setListData(data)
          setLoading(false)
        },
        (e) => {
          console.error(e)
          setListData([])
          setLoading(false)
        }
      )
  }
  const filteredItems = Object.keys((listData || {}).header || {}).filter(
    (headerItem) => {
      return headerItem !== 'id' && headerItem !== djangoModel
    }
  )
  const capitalizedHeader = { ...((listData || {}).header || {}) }
  Object.entries(capitalizedHeader).forEach(([key, value]) => {
    capitalizedHeader[key] = (value || '').toTitleCase()
  })

  const dateFieldSlots = Object.keys((listData || {}).header || {}).reduce(
    (reduced, item) => {
      if (item.indexOf('date') > -1) {
        reduced[item] = (row) => formattedDate(row[item])
      }
      return reduced
    },
    {}
  )

  const booleanFields = Object.entries(
    ((listData || {}).data || [{}])[0] || {}
  ).reduce((reduced, [itemKey, itemValue]) => {
    if (typeof itemValue === 'boolean') {
      reduced[itemKey] = (row) => (
        <td>{String(row[itemKey]).translate().toTitleCase()}</td>
      )
    }
    return reduced
  }, {})

  const defaultScopedSlots = filteredItems.reduce(
    (reduced, item) => ({
      ...reduced,
      [item]: (row) => <td>{row[item] !== null ? row[item] : 'N/E'}</td>,
    }),
    {}
  )

  const handleDeletion = (id) => {
    // TODO: Raise toasts
    ModelApi.shared
      .delete(token, fieldData.app_name, fieldData.model_name, id)
      .finally(() => setListData(null))
  }

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData || {}}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <CCard borderColor="secondary" style={{ padding: '1rem' }}>
        {loading && <p className="text-center muted">Carregando</p>}
        {listData && (
          <CDataTable
            hover
            striped
            bordered
            size="sm"
            pagination
            fields={[...filteredItems, 'actions']}
            noItemsViewSlot={<EmptyTableSlot />}
            columnHeaderSlot={{ ...capitalizedHeader, actions: 'Ações' }}
            items={listData.data || []}
            style={{ marginBottom: 0 }}
            scopedSlots={{
              ...defaultScopedSlots,
              ...dateFieldSlots,
              ...booleanFields,
              status: statusColumn,
              file: ({ file }) => (
                <td>
                  <DocumentCard file={file} />
                </td>
              ),
              actions: (item) => (
                <td align="right" style={{ width: '170px' }}>
                  <ActionButton
                    iconName="cil-pencil"
                    label="Editar"
                    onClick={() =>
                      onRelationChange(
                        fieldData.app_name,
                        fieldData.model_name,
                        item.id,
                        {},
                        {
                          [modelFieldName]: objectId,
                        },
                        () => setListData(null)
                      )
                    }
                  />
                  <ActionButton
                    iconName="cil-trash"
                    label="Excluir"
                    onClick={() => {
                      showModal({
                        onConfirm: () => handleDeletion(item.id),
                        title: 'Deseja mesmo excluir o registro?',
                        body: 'Esta ação não poderá ser desfeita',
                        doubleClickToConfirm: true,
                      })
                    }}
                  />
                </td>
              ),
            }}
          />
        )}
        <CButton
          color="dark"
          variant="outline"
          onClick={() => {
            onRelationChange(
              fieldData.app_name,
              fieldData.model_name,
              undefined,
              {},
              {
                [modelFieldName]: objectId,
              },
              () => setListData(null)
            )
          }}
        >
          <CIcon name="cil-plus" style={{ marginRight: '5px' }} />
          Novo
        </CButton>
      </CCard>
    </GenericInputGroup>
  )
}
export default EmbeddedFormInput
