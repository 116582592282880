import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import './extensions'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import { serviceWorkerOperations } from './state/ducks/serviceWorker'

React.icons = icons

if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept()
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: (reg) => serviceWorkerOperations.init(reg)(store.dispatch),
  onUpdate: (reg) => serviceWorkerOperations.update(reg)(store.dispatch),
})
