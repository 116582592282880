import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ModelApi } from 'src/api'
import { useDispatch } from 'react-redux'
import { toastOperations } from 'src/state/ducks/toast'

import illustration from 'src/assets/illustrations/undraw_Forgot_password_re_hxwm.png'

const NewPassword = () => {
  const [newPass, setNewPass] = useState(null)
  const [newPassRepeat, setNewPassRepeat] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const token = new URLSearchParams(window.location.search).get('token')

  const HandlePasswordRecovery = () => {
    return ModelApi.shared
      ._post(ModelApi.shared._getFullPath('/users/api/set-new-password'), {
        token,
        newPass,
      })
      .then(({ data }) => {
        dispatch(
          toastOperations.show(data.message, {
            appearance: 'success',
          })
        )
        history.push('/')
      })
      .catch((e) => {
        console.error(e)
        let error = 'Falha ao recuperar a senha'
        if (e?.response?.data) {
          if (Array.isArray(e.response.data?.errors)) {
            error = e.response.data?.errors.map((e) => <li>{e}</li>)
          } else if (typeof e.response.data == 'object') {
            error = JSON.stringify(e.response.data)
          } else {
            error = e.response.data
          }
        }
        dispatch(
          toastOperations.show(error, {
            appearance: 'error',
          })
        )
      })
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard
                className="text-white py-1 d-md-down-none"
                style={{ width: '44%' }}
              >
                <CCardBody
                  className="text-center"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '3rem',
                  }}
                >
                  <img
                    alt="login logo"
                    src={illustration}
                    style={{ maxWidth: '100%' }}
                  />
                </CCardBody>
              </CCard>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h2>Recuperação de senha</h2>
                    <p>Escolha a nova senha para sua conta</p>
                    <ul style={{ fontSize: 12 }} className="pl-3 m-0 mb-4">
                      <li>
                        Sua senha não pode ser muito parecida com o resto das
                        suas informações pessoais.
                      </li>
                      <li>Sua senha precisa conter pelo menos 8 caracteres.</li>
                      <li>
                        Sua senha não pode ser uma senha comumente utilizada.
                      </li>
                      <li>Sua senha não pode ser inteiramente numérica.</li>
                    </ul>
                    <CInputGroup className="mb-2">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder="Nova senha"
                        name="Nova senha"
                        autoComplete="Nova senha"
                        onChange={(e) => setNewPass(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder="Confirmar nova senha"
                        name="Confirmar nova senha"
                        autoComplete="Confirmar nova senha"
                        onChange={(e) => setNewPassRepeat(e.target.value)}
                      />
                    </CInputGroup>
                    {newPass &&
                      newPass.length >= 6 &&
                      (newPass === newPassRepeat ? (
                        <>
                          <CAlert className="mb-3" color="success">
                            As senhas coincidem
                          </CAlert>
                        </>
                      ) : (
                        <CAlert className="mb-3" color="danger">
                          As senhas não coincidem
                        </CAlert>
                      ))}
                    <div className="text-right">
                      <CButton
                        disabled={
                          !(
                            newPass &&
                            newPass.length >= 6 &&
                            newPass === newPassRepeat
                          )
                        }
                        data-testid="submit"
                        color="primary"
                        type="button"
                        className="px-4"
                        onClick={(e) => HandlePasswordRecovery(e)}
                      >
                        Salvar
                      </CButton>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default NewPassword
