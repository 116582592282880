import { useDispatch } from 'react-redux'
import { dismiss, show } from './actions'

export const useModal = () => {
  const dispatch = useDispatch()
  return (...params) => dispatch(show(...params))
}

export const useDismissModal = () => {
  const dispatch = useDispatch()
  return () => dispatch(dismiss())
}
