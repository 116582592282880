import React from 'react'
import { IMAGE_EXTENSIONS } from 'src/reusable/DjangoFormInputs/utils'
import { ModelApi } from 'src/api'
import CIcon from '@coreui/icons-react'
import './DocumentCard.scss'

const DocumentCard = React.memo(
  ({ name, note, file, onEditClick, style = {} }) => {
    const isImage = file && IMAGE_EXTENSIONS.some((ext) => file.endsWith(ext))
    const mediaSrc = ModelApi.getMediaSrc(file)
    const extension = file.split('.').pop()

    const handleDownload = () => {
      fetch(mediaSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const e = document.createElement('a')
          e.setAttribute('href', URL.createObjectURL(blob))
          e.setAttribute('download', name)
          document.body.appendChild(e)
          e.click()
          document.body.removeChild(e)
        })
    }

    return (
      <a
        href={mediaSrc}
        target="blank"
        className="file-card"
        style={{ background: isImage ? `url(${mediaSrc})` : null, ...style }}
      >
        {!!extension && <span className="extension-overlay">.{extension}</span>}
        {/* TODO: Make download button work */}
        <div className="download-wrapper">
          {/* eslint-disable-next-line */}
          <a
            className="download-icon"
            onClick={(e) => {
              e.preventDefault()
              handleDownload()
            }}
          >
            <CIcon name="cil-cloud-download" />
          </a>
          {onEditClick && (
            // eslint-disable-next-line
            <a
              className="download-icon"
              onClick={(e) => {
                e.preventDefault()
                onEditClick()
              }}
            >
              <CIcon name="cil-pencil" />
            </a>
          )}
        </div>
        <div className="name">
          {name}
          <small>{note}</small>
        </div>
      </a>
    )
  }
)

export default DocumentCard
