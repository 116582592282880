import React, { useState, Component, useEffect, useCallback } from 'react'
import {
  CInput,
  CLabel,
  CTextarea,
  CLink,
  CDataTable,
  CSwitch,
  CInputGroup,
  CInputGroupAppend,
  CButton,
} from '@coreui/react'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import CIcon from '@coreui/icons-react'
import Select from 'react-select'
import { SliderPicker } from 'react-color'
import { ModelApi } from 'src/api'
import { connect } from 'react-redux'
import moment from 'moment'

import { validateFieldValue } from './utils'

import GenericInputGroup from './GenericInputGroup'
import SelectInput from './SelectInput'
import FileInput from './FileInput'
import { formattedDate } from '../DjangoListColumnParsers'
import { ActionButton } from 'src/views/buttons'
import ModalComponent from 'src/components/ModalComponent'
import { moneyFormat, textInputMasks } from '../masks'
import { modalFormOperations } from 'src/state/ducks/modalForm'
import { EmptyTableSlot } from '../DjangoList'
import EmbeddedFormInput from './EmbbededForm'
import DocumentInput from '../DocumentInput'
import {
  API_DATETIME_WITH_TIMEZONE_FORMAT,
  DEFAULT_API_DATETIME_FORMAT,
  DEFAULT_API_DATE_FORMAT,
} from 'src/api/baseApi'
import RRuleGenerator from 'react-rrule-generator'

import 'react-rrule-generator/build/styles.css' // react-rrule-generator's custom CSS

const getModifiers = (attrs) =>
  textInputMasks[attrs?.mask] || {
    mask: (v) => v,
    clear: (v) => v,
    validator: () => true,
  }

export const TextInput = ({
  fieldName,
  fieldData,
  fieldErrors = [],
  value,
  onChange,
  submited,
}) => {
  const modifiers = getModifiers(fieldData.widget.attrs)
  const measurementUnit = fieldData.widget.attrs?.['measurement-unit']

  if (modifiers.validator(value || '')) {
    fieldErrors = fieldErrors.filter((error) => error !== modifiers.error)
  } else {
    value &&
      !fieldErrors.includes(modifiers.error) &&
      fieldErrors.push(modifiers.error)
  }

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      {fieldName.indexOf('color') > -1 ? (
        <SliderPicker
          color={value || ''}
          onChangeComplete={(color) => {
            const value = color.hex
            onChange(value, validateFieldValue(fieldData, value)[0])
          }}
        />
      ) : (
        <CInputGroup>
          <CInput
            value={modifiers.mask(value || '')}
            id={`field-${fieldName}`}
            name={fieldName}
            onChange={(e) => {
              const value = modifiers.clear(e.target.value)
              onChange(value, validateFieldValue(fieldData, value)[0])
            }}
            required={fieldData.required}
            maxLength={(fieldData.widget.attrs || {}).maxlength}
            readOnly={(fieldData.widget.attrs || {}).readonly}
            {...modifiers.inputProperties}
          />
          {!!measurementUnit && (
            <CInputGroupAppend>
              <CButton readonly color="secondary">
                {measurementUnit}
              </CButton>
            </CInputGroupAppend>
          )}
        </CInputGroup>
      )}
    </GenericInputGroup>
  )
}

export const RruleInput = ({
  fieldName,
  fieldData,
  fieldErrors = [],
  value,
  onChange,
  submited,
}) => {
  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <RRuleGenerator
        translations={{
          locale: 'pt-br',
          invalid_rrule:
            "Uma regra de repetição inválida foi fornecida. '%{value}' não segue o padrão RRule.",
          months: {
            jan: 'Jan',
            feb: 'Fev',
            mar: 'Mar',
            apr: 'Abr',
            may: 'Mai',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Ago',
            sep: 'Set',
            oct: 'Out',
            nov: 'Nov',
            dec: 'Dez',
          },
          days_short: {
            mon: 'Seg',
            tue: 'Ter',
            wed: 'Qua',
            thu: 'Qui',
            fri: 'Sex',
            sat: 'Sab',
            sun: 'Dom',
          },
          days: {
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sabado',
            sunday: 'Domingo',
            day: 'Dia',
            weekday: 'Dia da semana',
            'weekend day': 'Final de semana',
          },
          numerals: {
            first: 'Primeiro',
            second: 'Segundo',
            third: 'Terceiro',
            fourth: 'Quarto',
            last: 'Último',
          },
          start: {
            label: 'Inicio',
            tooltip: 'Seletor de data e hora para inicio',
          },
          repeat: {
            label: 'Repetir',
            yearly: {
              label: 'Anualmente',
              on: 'em',
              on_the: 'no',
              of: 'de',
            },
            monthly: {
              label: 'Mensalmente',
              every: 'todo',
              months: 'mes(es)',
              on_day: 'no dia',
              on_the: 'no',
            },
            weekly: {
              label: 'Semanalmente',
              every: 'toda',
              weeks: 'semana(s)',
            },
            daily: {
              label: 'Diáriamente',
              every: 'todo',
              days: 'dia(s)',
            },
            hourly: {
              label: 'De hora em hora',
              every: 'toda',
              hours: 'hora(s)',
            },
          },
          end: {
            label: 'Final',
            tooltip: 'Seletor de data e hora para final',
            never: 'Nunca',
            after: 'Depois de',
            on_date: 'Na data',
            executions: 'Ocorrências',
          },
        }}
        onChange={(rrule) =>
          onChange(rrule, validateFieldValue(fieldData, rrule))
        }
      />
    </GenericInputGroup>
  )
}

export const LoggedTextInput = connect(({ auth: { token } }) => ({ token }), {
  showModalForm: modalFormOperations.show,
})(
  ({
    token,
    fieldName,
    fieldData,
    fieldErrors,
    value,
    onChange,
    submited,
    onRelationChange,
    djangoModel,
    djangoApp,
    objectId,
  }) => {
    const [isModalOpen, setModalOpen] = useState()
    const [loading, setLoading] = useState()
    const [modelData, setModelData] = useState()

    const modifiers = getModifiers(fieldData.widget.attrs)

    const loadData = useCallback(() => {
      setLoading(true)
      ModelApi.shared
        .listLoggedField(token, djangoApp, djangoModel, objectId, fieldName)
        .then(
          ({ data }) => setModelData(data),
          () => setModelData([])
        )
        .finally(() => setLoading(false))
    }, [token, djangoApp, djangoModel, objectId, fieldName])

    useEffect(() => {
      if (isModalOpen && !modelData && !loading) {
        loadData()
      }
    }, [isModalOpen, modelData, loading, loadData])

    return (
      <GenericInputGroup
        fieldName={fieldName}
        fieldData={fieldData}
        fieldErrors={fieldErrors}
        value={value}
        submited={submited}
      >
        <div className="select-row">
          <CInput
            value={modifiers.mask(value || '')}
            id={`field-${fieldName}`}
            name={fieldName}
            onChange={(e) => {
              const value = modifiers.clear(e.target.value)
              onChange(value, validateFieldValue(fieldData, value)[0])
            }}
            required={fieldData.required}
            maxLength={(fieldData.widget.attrs || {}).maxlength}
            readOnly={(fieldData.widget.attrs || {}).readonly}
          />
          {!!onRelationChange && !!djangoModel && (
            <>
              {!!objectId && (
                <ActionButton
                  onClick={() => setModalOpen(true)}
                  iconName="cil-list"
                  label="Ver histórico"
                />
              )}
              <ModalComponent
                isOpen={isModalOpen}
                title="Listar histórico"
                onCancel={() => setModalOpen(false)}
                closeModal={() => setModalOpen(false)}
                onConfirm={() => setModalOpen(false)}
                body={() => (
                  <>
                    {/* <CButton
                      color="dark"
                      variant="outline"
                      size="sm"
                      onClick={() => showForm()}
                      style={{ width: '100%', marginBottom: '.5rem' }}
                    >
                      <CIcon name="cil-plus" style={{ marginRight: '5px' }} />
                      Novo
                    </CButton> */}
                    <CDataTable
                      hover
                      striped
                      bordered
                      size="sm"
                      pagination
                      fields={['datetime', 'value']}
                      noItemsViewSlot={<EmptyTableSlot />}
                      columnHeaderSlot={{
                        ...(modelData ? modelData.header : {}),
                        value: 'Valor',
                        datetime: 'Data',
                      }}
                      items={modelData ? modelData.data : []}
                      style={{ marginBottom: 0 }}
                      scopedSlots={{
                        datetime: ({ datetime }) => formattedDate(datetime),
                        value: ({ value }) => <td>{value || `-`}</td>,
                      }}
                    />
                  </>
                )}
              />
            </>
          )}
        </div>
      </GenericInputGroup>
    )
  }
)
export const LoggedCostInput = connect(({ auth: { token } }) => ({ token }), {
  showModalForm: modalFormOperations.show,
})(
  ({
    token,
    fieldName,
    fieldData,
    fieldErrors,
    value,
    onChange,
    submited,
    onRelationChange,
    djangoModel,
    djangoApp,
    objectId,
  }) => {
    const [isModalOpen, setModalOpen] = useState()
    const [loading, setLoading] = useState()
    const [modelData, setModelData] = useState()

    const modifiers = getModifiers(fieldData.widget.attrs)

    const loadData = useCallback(() => {
      setLoading(true)
      ModelApi.shared
        .listLoggedField(token, djangoApp, djangoModel, objectId, fieldName)
        .then(
          ({ data }) => setModelData(data),
          () => setModelData([])
        )
        .finally(() => setLoading(false))
    }, [token, djangoApp, djangoModel, objectId, fieldName])

    useEffect(() => {
      if (isModalOpen && !modelData && !loading) {
        loadData()
      }
    }, [isModalOpen, modelData, loading, loadData])

    return (
      <GenericInputGroup
        fieldName={fieldName}
        fieldData={fieldData}
        fieldErrors={fieldErrors}
        value={value}
        submited={submited}
      >
        <div className="select-row">
          <CInput
            value={modifiers.mask(value || '')}
            id={`field-${fieldName}`}
            name={fieldName}
            onChange={(e) => {
              const value = modifiers.clear(e.target.value)
              onChange(value, validateFieldValue(fieldData, value)[0])
            }}
            required={fieldData.required}
            maxLength={(fieldData.widget.attrs || {}).maxlength}
            readOnly={(fieldData.widget.attrs || {}).readonly}
          />
          {!!onRelationChange && !!djangoModel && (
            <>
              {!!objectId && (
                <ActionButton
                  onClick={() => setModalOpen(true)}
                  iconName="cil-list"
                  label="Ver histórico"
                />
              )}
              <ModalComponent
                isOpen={isModalOpen}
                title="Listar histórico"
                onCancel={() => setModalOpen(false)}
                closeModal={() => setModalOpen(false)}
                onConfirm={() => setModalOpen(false)}
                body={() => (
                  <>
                    {/* <CButton
                      color="dark"
                      variant="outline"
                      size="sm"
                      onClick={() => showForm()}
                      style={{ width: '100%', marginBottom: '.5rem' }}
                    >
                      <CIcon name="cil-plus" style={{ marginRight: '5px' }} />
                      Novo
                    </CButton> */}
                    <CDataTable
                      hover
                      striped
                      bordered
                      size="sm"
                      pagination
                      fields={['datetime', 'value']}
                      noItemsViewSlot={<EmptyTableSlot />}
                      columnHeaderSlot={{
                        ...(modelData ? modelData.header : {}),
                        value: 'Valor',
                        datetime: 'Data',
                      }}
                      items={modelData ? modelData.data : []}
                      style={{ marginBottom: 0 }}
                      scopedSlots={{
                        datetime: ({ datetime }) => formattedDate(datetime),
                        value: ({ value }) => (
                          <td>{moneyFormat(value || 0)}</td>
                        ),
                      }}
                    />
                  </>
                )}
              />
            </>
          )}
        </div>
      </GenericInputGroup>
    )
  }
)

export const DateTimeWithTimezoneInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
}) => {
  if (value) value = moment(value, API_DATETIME_WITH_TIMEZONE_FORMAT)

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <br />
      <KeyboardDateTimePicker
        value={value}
        placeholder="Clique para informar a data e hora"
        labelFunc={(obj) => (obj && obj.format('DD/MM/YYYY HH:mm')) || ''}
        onChange={(obj) => {
          const value = obj && obj.format(API_DATETIME_WITH_TIMEZONE_FORMAT)
          onChange(value, validateFieldValue(fieldData, value)[0])
        }}
        format="DD/MM/YYYY HH:mm"
        invalidDateMessage="O formato de data e hora informado é inválido, utilize o formato 01/01/2021 12:00"
        invalidLabel="O valor informado é inválido"
        style={{ width: '100%' }}
      />
    </GenericInputGroup>
  )
}

export const DateTimeInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
}) => {
  if (value) value = moment(value, DEFAULT_API_DATETIME_FORMAT)

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <br />
      <KeyboardDateTimePicker
        value={value}
        placeholder="Clique para informar a data e hora"
        labelFunc={(obj) => (obj && obj.format('DD/MM/YYYY HH:mm')) || ''}
        onChange={(obj) => {
          const value = obj && obj.format(DEFAULT_API_DATETIME_FORMAT)
          onChange(value, validateFieldValue(fieldData, value)[0])
        }}
        format="DD/MM/YYYY HH:mm"
        invalidDateMessage="O formato de data e hora informado é inválido, utilize o formato 01/01/2021 12:00"
        invalidLabel="O valor informado é inválido"
        style={{ width: '100%' }}
      />
    </GenericInputGroup>
  )
}

export const DateInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
}) => {
  if (value) value = moment(value, DEFAULT_API_DATE_FORMAT)

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <br />
      <KeyboardDatePicker
        value={value}
        placeholder="Clique para informar a data"
        labelFunc={(obj) => (obj && obj.format('DD/MM/YYYY')) || ''}
        onChange={(obj) => {
          const value = obj && obj.format(DEFAULT_API_DATE_FORMAT)
          onChange(value, validateFieldValue(fieldData, value)[0])
        }}
        format="DD/MM/YYYY"
        invalidDateMessage="O formato de data informado é inválido, utilize o formato 01/01/2021"
        invalidLabel="O valor informado é inválido"
        style={{ width: '100%' }}
      />
    </GenericInputGroup>
  )
}

export const TimeInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
}) => {
  if (value) value = moment(value, 'HH:mm')

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <br />
      <KeyboardTimePicker
        value={value}
        placeholder="Clique para informar a hora"
        labelFunc={(obj) => (obj && obj.format('HH:mm')) || ''}
        onChange={(obj) => {
          const value = obj && obj.format('HH:mm')
          onChange(value, validateFieldValue(fieldData, value)[0])
        }}
        format="HH:mm"
        invalidDateMessage="O formato de hora informado é inválido, utilize o formato 12:00"
        invalidLabel="O valor informado é inválido"
        style={{ width: '100%' }}
      />
    </GenericInputGroup>
  )
}

export const TextareaInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
}) => (
  <GenericInputGroup
    fieldName={fieldName}
    fieldData={fieldData}
    fieldErrors={fieldErrors}
    value={value}
    submited={submited}
  >
    <CTextarea
      id={`field-${fieldName}`}
      name={fieldName}
      value={value || ''}
      onChange={(e) => {
        const value = e.target.value
        onChange(value, validateFieldValue(fieldData, value)[0])
      }}
      required={fieldData.required}
      maxLength={(fieldData.widget.attrs || {}).maxlength}
    />
  </GenericInputGroup>
)

export const CheckboxInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
  disabled,
}) => (
  <GenericInputGroup
    fieldData={fieldData}
    fieldErrors={fieldErrors}
    value={value}
    submited={submited}
    formGroupProps={{
      variant: 'checkbox',
      className: 'checkbox',
      style: {
        margin: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
      },
    }}
  >
    <CSwitch
      id={`field-${fieldName}`}
      shape="pill"
      color="primary"
      name={fieldName}
      checked={value || false}
      onChange={(e) => {
        const value = e.target.checked
        onChange(value, validateFieldValue(fieldData, value)[0])
      }}
      disabled={disabled}
    />
    <CLabel
      variant="checkbox"
      className="form-check-label"
      htmlFor={`field-${fieldName}`}
      style={{
        marginLeft: '.5rem',
      }}
    >
      {(fieldData.label || '').toTitleCase()}
    </CLabel>
  </GenericInputGroup>
)

export const SelectMultipleInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
  onRelationChange,
  disabled = false,
}) => {
  const options = (fieldData.widget.choices || []).map(
    ({ value: optionValue, display }) => ({
      value: optionValue,
      label: display,
    })
  )

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <div
        className={`select-row ${
          (fieldErrors || []).length > 0 ? 'is-invalid' : ''
        }`}
      >
        <Select
          id={`field-${fieldName}`}
          className="select__basic-single"
          classNamePrefix="select"
          placeholder="Selecione uma ou mais opções"
          defaultValue={options.filter((opt) => opt.value === value)[0]}
          isSearchable={true}
          name={fieldName}
          options={options}
          onChange={(valueList) => {
            const value = (valueList || []).map(({ value }) => value)
            onChange(value, validateFieldValue(fieldData, value))
          }}
          isMulti
          isDisabled={disabled}
          closeMenuOnSelect={false}
        />
        {fieldData.model_name && onRelationChange && value && (
          <CLink
            onClick={() =>
              onRelationChange(fieldData.app_name, fieldData.model_name, value)
            }
            className="action"
          >
            <CIcon name="cil-pencil" />
            Editar
          </CLink>
        )}
        {fieldData.model_name && onRelationChange && (
          <CLink
            onClick={() =>
              onRelationChange(
                fieldData.app_name,
                fieldData.model_name,
                undefined
              )
            }
            className="action"
          >
            <CIcon name="cil-plus" />
            Novo
          </CLink>
        )}
      </div>
    </GenericInputGroup>
  )
}

export class IntervalSelectorInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMultiplier: this.multiplier,
      selectedRaw: this.initialValue,
    }
  }

  get selectOptions() {
    const {
      fieldData: { widget = {} },
    } = this.props

    if (widget.choices) {
      return (widget.choices || [])
        .map((opt) => ({
          label: opt.display,
          value: opt.value,
          multiplier: opt.multiplier,
        }))
        .sort((a, b) => b.multiplier - a.multiplier)
    } else {
      return [{ label: 'Unidade', value: null, multiplier: 1 }]
    }
  }

  get multiplier() {
    const {
      fieldData: { widget = {} },
      form,
    } = this.props
    var { value } = this.props

    if (widget.related_field) {
      const previousMultiplierValue = form.data[widget.related_field]
      const previousMultiplier = this.selectOptions.filter(
        (opt) => opt.value === previousMultiplierValue
      )

      if (previousMultiplier.length) {
        return previousMultiplier[0]
      }
    } else {
      for (const opt of this.selectOptions) {
        if (value % opt.multiplier === 0) return opt
      }
      return this.selectOptions[this.selectOptions.length - 1]
    }
    return null
  }

  get initialValue() {
    const { value } = this.props
    const { multiplier } = this.multiplier || {}

    if (multiplier) return value / multiplier

    return null
  }

  handleChange = (inputValue, inputMultiplier) => {
    const {
      onChange,
      fieldData: { widget = {} },
      fieldData,
    } = this.props
    const extraData = {}
    const multipliedValue =
      inputValue * ((inputMultiplier && inputMultiplier.multiplier) || 1)

    if (widget && widget.related_field)
      extraData[widget.related_field] = inputMultiplier
        ? inputMultiplier.value
        : 1

    onChange &&
      onChange(
        multipliedValue,
        validateFieldValue(fieldData, multipliedValue)[0],
        extraData
      )
  }

  render() {
    const { value, fieldErrors, disabled, fieldName, fieldData, submited } =
      this.props
    const { selectedRaw, selectedMultiplier } = this.state

    return (
      <GenericInputGroup
        fieldName={fieldName}
        fieldData={fieldData || {}}
        fieldErrors={fieldErrors}
        value={value}
        submited={submited}
      >
        <div
          className={`select-row ${
            (fieldErrors || []).length > 0 ? 'is-invalid' : ''
          }`}
        >
          <CInput
            id={`field-${fieldName}`}
            value={selectedRaw || ''}
            name={fieldName}
            onChange={(e) => {
              const inputValue = String(e.target.value || '').replace(
                /[^0-9.]/g,
                ''
              )
              this.setState({ selectedRaw: inputValue })
              this.handleChange(inputValue, selectedMultiplier)
            }}
            className="value-input"
            required={fieldData.required}
            disabled={disabled}
            maxLength={(fieldData.widget.attrs || {}).maxlength}
          />
          <Select
            id={`field-${fieldName}`}
            className="select__basic-single factor-select"
            classNamePrefix="select"
            placeholder="Selecione"
            isSearchable={true}
            name={fieldName}
            options={this.selectOptions}
            isDisabled={disabled}
            value={selectedMultiplier || null}
            onChange={(opt) => {
              this.setState({ selectedMultiplier: opt })
              this.handleChange(selectedRaw, opt)
            }}
          />
        </div>
      </GenericInputGroup>
    )
  }
}

const HiddenInput = () => null

const DjangoFormInputs = {
  text: TextInput,
  rrulefield: RruleInput,
  measurementunitfield: TextInput,
  loggedtextfield: LoggedTextInput,
  loggedfield: LoggedCostInput,
  textarea: TextareaInput,
  checkbox: CheckboxInput,
  select: SelectInput,
  selectmultiple: SelectInput,
  coloredselect: SelectInput,
  datetime: DateTimeInput,
  datetimewithtimezone: DateTimeWithTimezoneInput,
  date: DateInput,
  time: TimeInput,
  intervalselector: IntervalSelectorInput,
  embeddedform: (props) => {
    if ((props?.fieldData?.model_name || '').toLowerCase().includes('document'))
      return <DocumentInput {...props} />
    else return <EmbeddedFormInput {...props} />
  },
  hidden: HiddenInput,
  file: FileInput,
}

export default DjangoFormInputs
