import React from 'react'
import { CButton, CButtonGroup } from '@coreui/react'
import CIcon from '@coreui/icons-react'

const Paginator = ({ pageNumber, lastPage, onChange }) => {
  const paginatorRange = lastPage < 5 ? lastPage : 5
  const pageCompensation = ~~(paginatorRange / 2)

  const showDrillDown = pageNumber > 2
  const showPrevious = pageNumber > 1
  const showNext = pageNumber < lastPage
  const showDrillUp = pageNumber < lastPage - 1

  let firstPageOnList = pageNumber - pageCompensation
  if (firstPageOnList <= 0) firstPageOnList = 1
  else if (firstPageOnList + paginatorRange >= lastPage)
    firstPageOnList = lastPage - paginatorRange + 1

  const pageNumbers = []
  for (let i = 0; i < paginatorRange; i++) {
    const currentPage = firstPageOnList + i
    pageNumbers.push(currentPage)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CButtonGroup>
        {showDrillDown && (
          <CButton color="light" onClick={() => onChange(1)}>
            <CIcon name="cil-chevron-double-left" />
          </CButton>
        )}
        {showPrevious && (
          <CButton color="light" onClick={() => onChange(pageNumber - 1)}>
            <CIcon name="cil-chevron-left" />
          </CButton>
        )}
        {pageNumbers.map((page, idx) => (
          <CButton
            key={`paginator-button-${idx}`}
            color={page === pageNumber ? 'primary' : 'light'}
            onClick={() => onChange(page)}
          >
            {page}
          </CButton>
        ))}
        {showNext && (
          <CButton color="light" onClick={() => onChange(pageNumber + 1)}>
            <CIcon name="cil-chevron-right" />
          </CButton>
        )}
        {showDrillUp && (
          <CButton color="light" onClick={() => onChange(lastPage)}>
            <CIcon name="cil-chevron-double-right" />
          </CButton>
        )}
      </CButtonGroup>
    </div>
  )
}
export default Paginator
