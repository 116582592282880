import * as types from './types';

const reducer = (state = [], { type, payload }) => {
  switch (type) {
    case types.SHOW:
      return [...state, payload];
    case types.DISMISS:
      return state.filter(toast => toast.message !== payload)
    default:
      return state;
  }
};

export default reducer;
