import * as types from './types'
import { combineReducers } from 'redux'

const tokenReducer = (state = localStorage.getItem(types.LOGIN), action) => {
  switch (action.type) {
    case types.LOGIN:
      localStorage.setItem(types.LOGIN, action.payload)
      return action.payload

    case types.LOGOUT:
      localStorage.removeItem(types.LOGIN)
      return null

    case types.VERIFY_TOKEN:
      if (action.payload) return state
      else return null

    default:
      return state
  }
}

const userInfoReducer = (state = null, action) => {
  switch (action.type) {
    case types.GET_USER_INFO_ERROR:
      localStorage.clear()
      window.location.href = '/login'
      return {
        ...state,
        error: action.payload || 'error',
      }

    case types.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

const messageReducer = (state = null, action) => {
  switch (action.type) {
    case types.MESSAGE:
      return action.payload

    case types.DISMISS_MESSAGE:
      return null

    default:
      return state
  }
}

const reducer = combineReducers({
  token: tokenReducer,
  message: messageReducer,
  userInfo: userInfoReducer,
})

export default reducer
