import React, { Component } from 'react'
import CIcon from '@coreui/icons-react'
import { CButton, CSwitch } from '@coreui/react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { parseJson } from 'src/reusable/DjangoFormInputs/utils'
import { toastOperations } from 'src/state/ducks/toast'

class TableFieldsSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      selectedRows: props.defaultFields || [],
    }
  }

  get customTableFields() {
    const { djangoApp, djangoModel } = this.props

    const localStorageName = `tableFields/${djangoApp}/${djangoModel}`
    return (
      (localStorage.getItem(localStorageName) &&
        parseJson(localStorage.getItem(localStorageName))) ||
      []
    )
  }

  get orderedFields() {
    const { fields, defaultFields } = this.props
    const fieldEntries = Object.entries(fields)
    return [
      ...defaultFields.map((df) => fieldEntries.find(([k]) => k === df)),
      ...fieldEntries.filter(([key]) => !defaultFields.includes(key)),
    ].filter(Boolean)
  }

  get localStorageName() {
    const { djangoApp, djangoModel } = this.props

    return `tableFields/${djangoApp}/${djangoModel}`
  }
  componentDidMount() {
    const { onRowChange } = this.props

    const customTableFields = this.customTableFields

    customTableFields.length &&
      this.setState(
        { selectedRows: customTableFields },
        () => onRowChange && onRowChange(customTableFields)
      )
  }

  handleSaveChange = () => {
    const { defaultFields } = this.props
    const { selectedRows } = this.state

    selectedRows.equals(defaultFields)
      ? localStorage.removeItem(this.localStorageName)
      : localStorage.setItem(
          this.localStorageName,
          JSON.stringify(selectedRows)
        )
  }

  handleReset = () => {
    const { defaultFields, onRowChange } = this.props
    localStorage.removeItem(this.localStorageName)
    this.setState({ selectedRows: defaultFields }, () => {
      onRowChange && onRowChange(defaultFields)
    })
  }

  onRowChange = (key) => {
    const { onRowChange, showToast } = this.props
    const { selectedRows } = this.state

    let newRows = []
    selectedRows.includes(key)
      ? (newRows = selectedRows.filter((item) => item !== key))
      : (newRows = [...selectedRows, key])

    newRows = this.orderedFields
      .map(([k]) => k)
      .filter((field) => newRows.includes(field))

    newRows.length
      ? this.setState({ selectedRows: newRows }, () => {
          this.handleSaveChange()
          onRowChange && onRowChange(this.state.selectedRows)
        })
      : showToast('É necessário manter uma coluna na tabela.', {
          appearance: 'warning',
        })
  }

  render() {
    const { djangoApp, djangoModel } = this.props
    const { menuOpen, selectedRows } = this.state

    return (
      <>
        <div
          onClick={() => this.setState({ menuOpen: !menuOpen })}
          className="form-control table-fields-selector"
        >
          <span>Colunas</span>
          <CIcon
            name="cilChevronBottom"
            className={(menuOpen && 'chevron-open') || ''}
          />
        </div>
        <div
          className={`selector-dropdown ${(menuOpen && 'dropdown-open') || ''}`}
        >
          <CButton block color="secondary" onClick={this.handleReset}>
            Restaurar configuração
          </CButton>
          {this.orderedFields.map(([key, translated], idx) => (
            <div
              key={`table-fields-selector-${djangoApp}-${djangoModel}-${idx}`}
              className="dropdown-row"
            >
              <CSwitch
                checked={selectedRows.includes(key)}
                onChange={() => this.onRowChange(key)}
                color={'primary'}
                shape={'pill'}
                size={'sm'}
              />
              <span className="column-title">{translated.toTitleCase()}</span>
            </div>
          ))}
        </div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showToast: toastOperations.show }, dispatch)
}

export default connect(null, mapDispatchToProps)(TableFieldsSelector)
