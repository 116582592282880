import * as types from './types'

const reducer = (state = { isOpen: false }, { type, payload }) => {
  switch (type) {
    case types.SHOW:
      if (window.fcWidget) window.fcWidget.hide()
      return {
        ...payload,
        isOpen: true,
      }

    case types.DISMISS:
      if (window.fcWidget) window.fcWidget.show()
      return { isOpen: false }

    default:
      return state
  }
}

export default reducer
