import BasePrevenApiClient from './baseApi'

export const BASE_URL = process.env.REACT_APP_DJANGO_BACKEND_URL
export const BASE_PATH = process.env.REACT_APP_DJANGO_BACKEND_PATH || ''

class AuthApi extends BasePrevenApiClient {
  /**
   * Returns a shared api instance
   *
   * @returns {AuthApi} Api shared instance
   */
  static get shared() {
    return super.shared
  }

  /**
   * Handle user login
   *
   * @param {string} username User's name
   * @param {string} password User's pass
   * @param {string} databaseId Users database identifier
   */
  login(username, password, databaseId) {
    this.databaseId = databaseId

    const path = this._getFullPath('/authentication/')

    return this._post(path, { username, password })
  }

  getUserInfo(authToken) {
    const path = this._getFullPath('/authentication/user-info')

    return this._get(path, { authToken })
  }

  /**
   * Redirects the user to the django backend so we can keep the session when accessing django routes
   *
   * @param {string} next An url to redirect the user after the authentication process
   */
  handleSessionPersistance(token, next, newTab = false) {
    const path = this._getFullPath('/authentication/login', {
      token,
      next: next.replace(/&/g, '%26'),
    })

    if (newTab) window.open(path)
    else window.location.href = path
  }
}

export default AuthApi
