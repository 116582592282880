import React from 'react'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import { CCard, CCardBody } from '@coreui/react'
import { isMobile } from 'src/reusable/DjangoList'
import CIcon from '@coreui/icons-react'

const TimelineEvent = ({ date, label, style, dotStyle, iconName }) =>
  !isMobile.any() ? (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot style={dotStyle} color="primary">
          {iconName && <CIcon name={iconName} />}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {!!date && <i>{date}</i>}
        <CCard style={style}>
          <CCardBody>{label}</CCardBody>
        </CCard>
      </TimelineContent>
    </TimelineItem>
  ) : (
    <CCard>
      <CCardBody>
        <small>{date}</small>
        <br />
        {label}
      </CCardBody>
    </CCard>
  )

export default TimelineEvent
