import { default as auth } from './ducks/auth'
import { default as loader } from './ducks/loader'
import { default as sidebarShow } from './ducks/sidebar'
import { default as navigation } from './ducks/navigation'
import { default as toast } from './ducks/toast'
import { default as company } from './ducks/company'
import { default as modal } from './ducks/modal'
import { default as modalForm } from './ducks/modalForm'
import { default as serviceWorker } from './ducks/serviceWorker'
import { combineReducers } from 'redux'

export default combineReducers({
  auth,
  loader,
  sidebarShow,
  navigation,
  toast,
  company,
  modal,
  modalForm,
  serviceWorker,
})
