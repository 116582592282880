const TRANSLATIONS = {
  // Maintenance Types
  corrective: 'corretiva',
  predictive: 'preditiva',
  preventive: 'preventiva',
  true: 'sim',
  false: 'não',

  // Permission Types
  permission: 'Permissão',
  can_change: 'Edição',
  can_add: 'Criação',
  can_view: 'Visualização',
  can_delete: 'Exclusão',

  // Django Apps
  auth: 'Autenticação',
  contenttypes: 'Tipo de conteúdo',
  admin: 'Administração',
  sessions: 'Sessão',
  authtoken: 'Token de autorização',
  users: 'Usuários',
  utilities: 'Utilitários',
  company: 'Empresa',
  stock: 'Estoque',
  provider: 'Fornecedor',
  maintenance: 'Manutenção',
  equipment: 'Equipamento',

  // Django Models
  group: 'Grupo',
  'content-type': 'Tipo de conteudo',
  contenttype: 'Tipo de conteudo',
  'log-entry': 'Registro de log',
  logentry: 'Registro de log',
  session: 'Sessão',
  token: 'token',
  'preven-user': 'Usuário',
  prevenuser: 'Usuário',
  team: 'Time',
  prevenuserrole: 'Função do Usuário',
  'preven-log-entry': 'Registro de log do Preven',
  prevenlogentry: 'Registro de log do Preven',
  'access-profile': 'Perfil de acesso',
  accessprofile: 'Perfil de acesso',
  'measurement-unit': 'Unidade de medida',
  measurementunit: 'Unidade de medida',
  'database-import': 'Importação de DB',
  databaseimport: 'Importação de DB',
  employee: 'Funcionário',
  'employee-expense': 'Custo de funcionário',
  employeeexpense: 'Custo de funcionário',
  shift: 'Turno',
  'non-working-day': 'Dia não util',
  nonworkingday: 'Dia não util',
  'expense-center': 'Centro de custo',
  expensecenter: 'Centro de custo',
  sector: 'Setor',
  'sector-stopped-expense-log': 'Log de Custo de parada do setor',
  sectorstoppedexpense: 'Custo de parada do setor',
  warehouse: 'Almoxarifado',
  'item-family': 'Familia do item',
  itemfamily: 'Familia do item',
  'item-category': 'Categoria do item',
  itemcategory: 'Categoria do item',
  item: 'Item',
  providercategory: 'Categoria do fornecedor',
  'urgency-level': 'Nivel de urgência',
  urgencylevel: 'Nivel de urgência',
  'maintenance-provider': 'Prestador da manutenção',
  maintenanceprovider: 'Prestador da manutenção',
  'maintenance-nature': 'Natureza da manutenção',
  maintenancenature: 'Natureza da manutenção',
  'maintenance-item': 'Item da manutenção',
  maintenanceitem: 'Item da manutenção',
  'maintenance-employee': 'Funcionário da manutenção',
  maintenanceemployee: 'Funcionário da manutenção',
  'delay-reason': 'Motivo de atraso',
  delayreason: 'Motivo de atraso',
  'conservation-state': 'Estado de conservação',
  conservationstate: 'Estado de conservação',
  'maintenance-order': 'Ordem de manutenção',
  maintenanceorder: 'Ordem de manutenção',
  'maintenance-order-employee': 'Funcionario da ordem de manutenção',
  maintenanceorderemployee: 'Funcionario da ordem de manutenção',
  'maintenance-order-provider': 'Fornecedor da ordem de manutenção',
  maintenanceorderprovider: 'Fornecedor da ordem de manutenção',
  'maintenance-order-item': 'Item da ordem de manutenção',
  maintenanceorderitem: 'Item da ordem de manutenção',
  'maintenance-order-employee-attendance':
    'Atendimento do funcionário à ordem de manutenção',
  maintenanceorderemployeeattendance:
    'Atendimento do funcionário à ordem de manutenção',
  'maintenance-status': 'Status da manutenção',
  maintenancestatus: 'Status da manutenção',
  maintenancetype: 'Tipo da manutenção',
  'equipment-group': 'Grupo de equipamento',
  equipmentgroup: 'Grupo de equipamento',
  'equipment-cost': 'Custo de equipamento',
  equipmentcost: 'Custo de equipamento',

  pending: 'Pendente',
  late: 'Atrasado',
  today: 'Hoje',
  future: 'Futuro',
  done: 'Concluído',
  canceled: 'Cancelado',
  reprogrammed: 'Reprogramado',

  stop: 'Parada',
  annotation: 'Anotação',
}

export default TRANSLATIONS
