import * as types from './types';

import { verifyToken } from './actions';

export { login, logout, resetPassword, verifyToken } from './actions';

export const checkStorageToken = () => async (dispatch) => {
  const token = localStorage.getItem(types.LOGIN);
  
  if (token)
    verifyToken(token)(dispatch)
};
