import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { DefaultToast } from 'react-toast-notifications'
import ActivityIndicator from 'src/components/ActivityIndicator/ActivityIndicator'
import Modal from 'src/components/ModalComponent'
import { DjangoFormModal } from './reusable/DjangoForm'
import './scss/style.scss'
import { modalFormOperations } from './state/ducks/modalForm'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import 'moment/locale/pt-br'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './hotjar'
import { defaultMaterialTheme } from './reusable/muiTheme'
import Firebase from './firebase'
import { toastOperations } from './state/ducks/toast'
import { CButton } from '@coreui/react'
import { useReloadSW } from './state/ducks/serviceWorker/hooks'
import { useIsLoading } from './state/ducks/loader/hooks'
import { companyOperations } from './state/ducks/company'
import { AuthApi } from './api'
import NewPassword from './views/pages/new-password/NewPassword'

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  })

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const PasswordRecovery = React.lazy(() =>
  import('./views/pages/password-recovery/PasswordRecovery')
)
const CompanyInput = React.lazy(() =>
  import('./views/pages/company-input/CompanyInput')
)
const ServiceRequestExternal = React.lazy(() =>
  import('./views/pages/service-request-external/ServiceRequestExternal')
)
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const reloadSW = useReloadSW()
  const dispatch = useDispatch()

  const {
    toast,
    auth: { token },
    modal: { props: modalProps, isOpen: isModalOpen },
    modalForm: { props: modalFormProps, isOpen: isModalFormOpen },
    serviceWorker,
  } = useSelector((state) => state)
  const isLoading = useIsLoading()

  useEffect(() => {
    Firebase.init()
  }, [])

  useEffect(() => {
    const now = +new Date() / 1000
    const beSessionPersisted = localStorage.getItem('backendSessionPersisted')

    if (token && (!beSessionPersisted || now - beSessionPersisted > 1 * 60)) {
      localStorage.setItem('backendSessionPersisted', now)
      AuthApi.shared.handleSessionPersistance(token, String(window.location))
    }
  }, [token])

  useEffect(() => {
    if (token) companyOperations.list(token)(dispatch)
  }, [token, dispatch]) // eslint-disable-line

  const apiRegex = /^\/backend\/.*/

  if (apiRegex.test(window.location.pathname)) {
    return <div />
  } else {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <MuiPickersUtilsProvider locale={'pt-br'} utils={MomentUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              {(isLoading && <ActivityIndicator />) || null}
              <Modal isOpen={isModalOpen} {...modalProps} />
              {isModalFormOpen && (
                <DjangoFormModal
                  {...modalFormProps}
                  shouldRedirectBack={false}
                  onRelationChange
                  {...(modalFormProps.modalProps || {})}
                  onSubmit={(...data) => {
                    dispatch(modalFormOperations.dismiss())
                    modalFormProps.onSubmit && modalFormProps.onSubmit(...data)
                  }}
                  onClose={(...data) => {
                    dispatch(modalFormOperations.dismiss())
                    modalFormProps.onClose && modalFormProps.onClose(...data)
                  }}
                />
              )}
              <div className="toast-container">
                {(toast || []).map((t) => (
                  <DefaultToast
                    appearance="info"
                    placement="top-right"
                    onDismiss={() =>
                      dispatch(toastOperations.dismiss(t.message))
                    }
                    {...(t.props || {})}
                  >
                    {t.message}
                  </DefaultToast>
                ))}
                {serviceWorker.updated && (
                  <DefaultToast appearance="info" placement="top-right">
                    Existe uma atualização disponível! <br />
                    <CButton
                      size="sm"
                      color="primary"
                      variant="outline"
                      style={{ marginTop: '.2rem' }}
                      onClick={reloadSW}
                    >
                      Atualizar
                    </CButton>
                  </DefaultToast>
                )}
              </div>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/new-password"
                  name="New Password Page"
                  render={(props) => <NewPassword {...props} />}
                />
                <Route
                  exact
                  path="/password-recovery"
                  name="Password Recovery Page"
                  render={(props) => <PasswordRecovery {...props} />}
                />
                <Route
                  exact
                  path="/company-input"
                  name="Company Input Page"
                  render={(props) => <CompanyInput {...props} />}
                />
                <Route
                  exact
                  path="/service-request"
                  name="Solicitação de serviço"
                  render={(props) => <ServiceRequestExternal {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
