import * as types from './types'
import { ModelApi } from 'src/api'

export const list = (token, callback = null) => (dispatch) => {
  ModelApi.shared.list(token, 'company', 'company', {}, 1, 999).then(
    ({ data }) => {
      dispatch({
        type: types.LIST,
        payload: data.data,
      })
      callback && callback(data)
    },
    (error) => {
      callback && callback(error)
    }
  )
}

export const select = (token, company) => ({
  type: types.SELECT,
  payload: company,
})
