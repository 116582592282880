Object.buildQueryString = function (obj) {
  return (
    '?' +
    Object.entries(obj)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => {
        if (Array.isArray(value))
          return value.map((v) => `${key}=${v}`).join('&')

        return `${key}=${value}`
      })
      .join('&')
  )
}
