import React, { useState } from 'react'
import PropTypes from 'prop-types'
import widgetTypes, { TextInput } from './DjangoFormInputs'
import { DjangoRemoteFormPropTypes } from './_types.d.ts'
import { CCard, CCardBody, CCardHeader, CCollapse } from '@coreui/react'
import useLocalStorage from './useLocalStorage'
import CIcon from '@coreui/icons-react'
import { PREFERENCES } from './constants'

const DjangoFormContent = ({
  authToken,
  form,
  data,
  onFieldChange,
  onRelationChange,
  fixedData,
  objectId,
  djangoApp,
  djangoModel,
  submited,
  showRelatedFieldsActions = true,
}) => {
  const [useSimplifiedForm] = useLocalStorage(PREFERENCES.SIMPLE_FORMS)
  const [advancedVisible, setAdvancedVisible] = useState(false)

  if (!form) return null

  const { fields, errors, ordered_fields: orderedFields = [] } = form

  const renderField = (fieldName, idx) => {
    const fieldData = fields[fieldName]
    const fieldValue = data[fieldName]
    const fieldErrors = errors[fieldName]

    const { widget } = fieldData

    let InputElement = widgetTypes[widget.input_type] || TextInput

    if (fixedData[fieldName] !== undefined) return null

    return (
      <InputElement
        key={`django-form-field-${djangoModel}-${fieldName}-${idx}`}
        authToken={authToken}
        fieldName={fieldName}
        fieldData={fieldData}
        fieldErrors={fieldErrors}
        value={fieldValue}
        submited={submited}
        onChange={(value, isValid, extraData) =>
          onFieldChange(value, isValid, extraData, fieldName, fieldData)
        }
        onRelationChange={showRelatedFieldsActions ? onRelationChange : null}
        form={form}
        disabled={
          !!fixedData[fieldName] ||
          fieldData?.widget?.attrs?.disabled ||
          fieldData?.widget?.attrs?.readonly
        }
        objectId={objectId}
        djangoApp={djangoApp}
        djangoModel={djangoModel}
      />
    )
  }

  if (!useSimplifiedForm) {
    return (orderedFields || []).map(renderField)
  }

  const isFieldRequired = (fieldName) => fields[fieldName]?.required
  const requiredFields = orderedFields.filter((fieldName) =>
    isFieldRequired(fieldName)
  )
  const nonRequiredFields = orderedFields.filter(
    (fieldName) => !isFieldRequired(fieldName)
  )

  return (
    <>
      {(requiredFields || []).map(renderField)}
      <CCard className="mt-4 mb-0">
        <CCardHeader onClick={() => setAdvancedVisible((prev) => !prev)}>
          <CIcon
            size="sm"
            className="mr-2"
            name={advancedVisible ? 'cil-chevron-bottom' : 'cil-chevron-right'}
          />
          <b>Opções avançadas</b>
        </CCardHeader>
        <CCollapse show={advancedVisible}>
          <CCardBody>{(nonRequiredFields || []).map(renderField)}</CCardBody>
        </CCollapse>
      </CCard>
    </>
  )
}

DjangoFormContent.propTypes = {
  authToken: PropTypes.string,
  form: DjangoRemoteFormPropTypes,
  data: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func,
  onRelationChange: PropTypes.func,
  fixedData: PropTypes.object,
  objectId: PropTypes.number,
  djangoApp: PropTypes.string,
  djangoModel: PropTypes.string,
  submited: PropTypes.bool,
}

export default DjangoFormContent
