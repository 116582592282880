import { useDispatch, useSelector } from 'react-redux'
import { start, stop } from './actions'
import { checkIfAnyLoading, checkIfLoading } from './selectors'

export const useLoader = () => {
  const dispatch = useDispatch()
  return [
    (...params) => dispatch(start(...params)),
    (...params) => dispatch(stop(...params)),
  ]
}

export const useIsLoading = (loadingKey) =>
  useSelector((state) =>
    loadingKey ? checkIfLoading(state, loadingKey) : checkIfAnyLoading(state)
  )
