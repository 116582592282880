const djangoPermissions = {
  auth: {
    permission: {
      can_add: 'auth.add_permission',
      can_change: 'auth.change_permission',
      can_delete: 'auth.delete_permission',
      can_view: 'auth.view_permission',
    },
    group: {
      can_add: 'auth.add_group',
      can_change: 'auth.change_group',
      can_delete: 'auth.delete_group',
      can_view: 'auth.view_group',
    },
  },
  contenttypes: {
    contenttype: {
      can_add: 'contenttypes.add_contenttype',
      can_change: 'contenttypes.change_contenttype',
      can_delete: 'contenttypes.delete_contenttype',
      can_view: 'contenttypes.view_contenttype',
    },
  },
  admin: {
    logentry: {
      can_add: 'admin.add_logentry',
      can_change: 'admin.change_logentry',
      can_delete: 'admin.delete_logentry',
      can_view: 'admin.view_logentry',
    },
  },
  sessions: {
    session: {
      can_add: 'sessions.add_session',
      can_change: 'sessions.change_session',
      can_delete: 'sessions.delete_session',
      can_view: 'sessions.view_session',
    },
  },
  authtoken: {
    token: {
      can_add: 'authtoken.add_token',
      can_change: 'authtoken.change_token',
      can_delete: 'authtoken.delete_token',
      can_view: 'authtoken.view_token',
    },
  },
  users: {
    prevenuser: {
      can_add: 'users.add_prevenuser',
      can_change: 'users.change_prevenuser',
      can_delete: 'users.delete_prevenuser',
      can_view: 'users.view_prevenuser',
    },
    team: {
      can_add: 'users.add_team',
      can_change: 'users.change_team',
      can_delete: 'users.delete_team',
      can_view: 'users.view_team',
    },
    prevenuserrole: {
      can_add: 'users.add_prevenuserrole',
      can_change: 'users.change_prevenuserrole',
      can_delete: 'users.delete_prevenuserrole',
      can_view: 'users.view_prevenuserrole',
    },
    prevenlogentry: {
      can_add: 'users.add_prevenlogentry',
      can_change: 'users.change_prevenlogentry',
      can_delete: 'users.delete_prevenlogentry',
      can_view: 'users.view_prevenlogentry',
    },
    accessprofile: {
      can_add: 'users.add_accessprofile',
      can_change: 'users.change_accessprofile',
      can_delete: 'users.delete_accessprofile',
      can_view: 'users.view_accessprofile',
    },
  },
  utilities: {
    measurementunit: {
      can_add: 'utilities.add_measurementunit',
      can_change: 'utilities.change_measurementunit',
      can_delete: 'utilities.delete_measurementunit',
      can_view: 'utilities.view_measurementunit',
    },
    databaseimport: {
      can_add: 'utilities.add_databaseimport',
      can_change: 'utilities.change_databaseimport',
      can_delete: 'utilities.delete_databaseimport',
      can_view: 'utilities.view_databaseimport',
    },
  },
  company: {
    company: {
      can_add: 'company.add_company',
      can_change: 'company.change_company',
      can_delete: 'company.delete_company',
      can_view: 'company.view_company',
    },
    companybackoffice: {
      can_add: 'company.add_company',
      can_change: 'company.change_company',
      can_delete: 'company.delete_company',
      can_view: 'company.view_company',
    },
    employee: {
      can_add: 'company.add_employee',
      can_change: 'company.change_employee',
      can_delete: 'company.delete_employee',
      can_view: 'company.view_employee',
    },
    employeeexpense: {
      can_add: 'company.add_employeeexpense',
      can_change: 'company.change_employeeexpense',
      can_delete: 'company.delete_employeeexpense',
      can_view: 'company.view_employeeexpense',
    },
    shift: {
      can_add: 'company.add_shift',
      can_change: 'company.change_shift',
      can_delete: 'company.delete_shift',
      can_view: 'company.view_shift',
    },
    nonworkingday: {
      can_add: 'company.add_nonworkingday',
      can_change: 'company.change_nonworkingday',
      can_delete: 'company.delete_nonworkingday',
      can_view: 'company.view_nonworkingday',
    },
    expensecenter: {
      can_add: 'company.add_expensecenter',
      can_change: 'company.change_expensecenter',
      can_delete: 'company.delete_expensecenter',
      can_view: 'company.view_expensecenter',
    },
    sector: {
      can_add: 'company.add_sector',
      can_change: 'company.change_sector',
      can_delete: 'company.delete_sector',
      can_view: 'company.view_sector',
    },
    sectorstoppedexpense: {
      can_add: 'company.add_sectorstoppedexpense',
      can_change: 'company.change_sectorstoppedexpense',
      can_delete: 'company.delete_sectorstoppedexpense',
      can_view: 'company.view_sectorstoppedexpense',
    },
    sidebarmenu: {
      can_add: 'company.add_sidebarmenu',
      can_change: 'company.change_sidebarmenu',
      can_delete: 'company.delete_sidebarmenu',
      can_view: 'company.view_sidebarmenu',
    },
  },
  stock: {
    warehouse: {
      can_add: 'stock.add_warehouse',
      can_change: 'stock.change_warehouse',
      can_delete: 'stock.delete_warehouse',
      can_view: 'stock.view_warehouse',
    },
    itemfamily: {
      can_add: 'stock.add_itemfamily',
      can_change: 'stock.change_itemfamily',
      can_delete: 'stock.delete_itemfamily',
      can_view: 'stock.view_itemfamily',
    },
    itemcategory: {
      can_add: 'stock.add_itemcategory',
      can_change: 'stock.change_itemcategory',
      can_delete: 'stock.delete_itemcategory',
      can_view: 'stock.view_itemcategory',
    },
    item: {
      can_add: 'stock.add_item',
      can_change: 'stock.change_item',
      can_delete: 'stock.delete_item',
      can_view: 'stock.view_item',
    },
  },
  provider: {
    providercategory: {
      can_add: 'provider.add_providercategory',
      can_change: 'provider.change_providercategory',
      can_delete: 'provider.delete_providercategory',
      can_view: 'provider.view_providercategory',
    },
    provider: {
      can_add: 'provider.add_provider',
      can_change: 'provider.change_provider',
      can_delete: 'provider.delete_provider',
      can_view: 'provider.view_provider',
    },
    providerorder: {
      can_add: 'provider.add_providerorder',
      can_change: 'provider.change_providerorder',
      can_delete: 'provider.delete_providerorder',
      can_view: 'provider.view_providerorder',
    },
  },
  maintenance: {
    maintenance: {
      can_add: 'maintenance.add_maintenance',
      can_change: 'maintenance.change_maintenance',
      can_delete: 'maintenance.delete_maintenance',
      can_view: 'maintenance.view_maintenance',
    },
    urgencylevel: {
      can_add: 'maintenance.add_urgencylevel',
      can_change: 'maintenance.change_urgencylevel',
      can_delete: 'maintenance.delete_urgencylevel',
      can_view: 'maintenance.view_urgencylevel',
    },
    maintenanceprovider: {
      can_add: 'maintenance.add_maintenanceprovider',
      can_change: 'maintenance.change_maintenanceprovider',
      can_delete: 'maintenance.delete_maintenanceprovider',
      can_view: 'maintenance.view_maintenanceprovider',
    },
    maintenancenature: {
      can_add: 'maintenance.add_maintenancenature',
      can_change: 'maintenance.change_maintenancenature',
      can_delete: 'maintenance.delete_maintenancenature',
      can_view: 'maintenance.view_maintenancenature',
    },
    maintenanceitem: {
      can_add: 'maintenance.add_maintenanceitem',
      can_change: 'maintenance.change_maintenanceitem',
      can_delete: 'maintenance.delete_maintenanceitem',
      can_view: 'maintenance.view_maintenanceitem',
    },
    maintenanceemployee: {
      can_add: 'maintenance.add_maintenanceemployee',
      can_change: 'maintenance.change_maintenanceemployee',
      can_delete: 'maintenance.delete_maintenanceemployee',
      can_view: 'maintenance.view_maintenanceemployee',
    },
    delayreason: {
      can_add: 'maintenance.add_delayreason',
      can_change: 'maintenance.change_delayreason',
      can_delete: 'maintenance.delete_delayreason',
      can_view: 'maintenance.view_delayreason',
    },
    conservationstate: {
      can_add: 'maintenance.add_conservationstate',
      can_change: 'maintenance.change_conservationstate',
      can_delete: 'maintenance.delete_conservationstate',
      can_view: 'maintenance.view_conservationstate',
    },
    maintenanceorder: {
      can_add: 'maintenance.add_maintenanceorder',
      can_change: 'maintenance.change_maintenanceorder',
      can_delete: 'maintenance.delete_maintenanceorder',
      can_view: 'maintenance.view_maintenanceorder',
    },
    maintenanceorderemployee: {
      can_add: 'maintenance.add_maintenanceorderemployee',
      can_change: 'maintenance.change_maintenanceorderemployee',
      can_delete: 'maintenance.delete_maintenanceorderemployee',
      can_view: 'maintenance.view_maintenanceorderemployee',
    },
    maintenanceorderprovider: {
      can_add: 'maintenance.add_maintenanceorderprovider',
      can_change: 'maintenance.change_maintenanceorderprovider',
      can_delete: 'maintenance.delete_maintenanceorderprovider',
      can_view: 'maintenance.view_maintenanceorderprovider',
    },
    maintenanceorderitem: {
      can_add: 'maintenance.add_maintenanceorderitem',
      can_change: 'maintenance.change_maintenanceorderitem',
      can_delete: 'maintenance.delete_maintenanceorderitem',
      can_view: 'maintenance.view_maintenanceorderitem',
    },
    maintenanceorderemployeeattendance: {
      can_add: 'maintenance.add_maintenanceorderemployeeattendance',
      can_change: 'maintenance.change_maintenanceorderemployeeattendance',
      can_delete: 'maintenance.delete_maintenanceorderemployeeattendance',
      can_view: 'maintenance.view_maintenanceorderemployeeattendance',
    },
    maintenancestatus: {
      can_add: 'maintenance.add_maintenancestatus',
      can_change: 'maintenance.change_maintenancestatus',
      can_delete: 'maintenance.delete_maintenancestatus',
      can_view: 'maintenance.view_maintenancestatus',
    },
    maintenancetype: {
      can_add: 'maintenance.add_maintenancetype',
      can_change: 'maintenance.change_maintenancetype',
      can_delete: 'maintenance.delete_maintenancetype',
      can_view: 'maintenance.view_maintenancetype',
    },
    servicesolicitationaccesstoken: {
      can_add: 'maintenance.add_servicesolicitationaccesstoken',
      can_change: 'maintenance.change_servicesolicitationaccesstoken',
      can_delete: 'maintenance.delete_servicesolicitationaccesstoken',
      can_view: 'maintenance.view_servicesolicitationaccesstoken',
    },
  },
  equipment: {
    equipment: {
      can_add: 'equipment.add_equipment',
      can_change: 'equipment.change_equipment',
      can_delete: 'equipment.delete_equipment',
      can_view: 'equipment.view_equipment',
    },
    equipmentgroup: {
      can_add: 'equipment.add_equipmentgroup',
      can_change: 'equipment.change_equipmentgroup',
      can_delete: 'equipment.delete_equipmentgroup',
      can_view: 'equipment.view_equipmentgroup',
    },
    equipmentcost: {
      can_add: 'equipment.add_equipmentcost',
      can_change: 'equipment.change_equipmentcost',
      can_delete: 'equipment.delete_equipmentcost',
      can_view: 'equipment.view_equipmentcost',
    },
  },
  telemetry: {
    telemetrydevice: {
      can_add: 'telemetry.add_telemetrydevice',
      can_change: 'telemetry.change_telemetrydevice',
      can_delete: 'telemetry.delete_telemetrydevice',
      can_view: 'telemetry.view_telemetrydevice',
    },
  },
  manufacture: {
    manufactureitem: {
      can_add: 'manufacture.add_manufactureitem',
      can_change: 'manufacture.change_manufactureitem',
      can_delete: 'manufacture.delete_manufactureitem',
      can_view: 'manufacture.view_manufactureitem',
    },
  },
}

export default djangoPermissions
