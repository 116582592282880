import { CFormGroup, CLabel, CValidFeedback } from '@coreui/react'
import React from 'react'
import RequiredIndicator from './RequiredIndicator'
import { validateFieldValue } from './utils'

const GenericInputGroup = ({
  fieldName,
  fieldData,
  fieldErrors = [],
  value,
  submited,
  children,
  formGroupProps = {},
}) => {
  const hasErrors = fieldErrors.length > 0
  const [isValid, validationMessage] = validateFieldValue(fieldData, value)

  return (
    <CFormGroup style={{ marginBottom: '10px' }} {...formGroupProps}>
      {fieldName && (
        <CLabel
          htmlFor={`field-${fieldName}`}
          className={hasErrors ? 'font-weight-bold' : ''}
        >
          {(fieldData.label || '').toTitleCase()}
          {fieldData.required && <RequiredIndicator />}
        </CLabel>
      )}
      {React.Children.toArray(children).map((Child) =>
        React.cloneElement(Child, {
          valid: !hasErrors && submited && isValid,
          invalid: hasErrors || (submited && !isValid),
        })
      )}
      {fieldData.help_text && (
        <small className="muted">{fieldData.help_text}</small>
      )}
      {!hasErrors && submited && isValid && (
        <CValidFeedback>{validationMessage}</CValidFeedback>
      )}
      {hasErrors
        ? fieldErrors.map((error, idx) => (
            <small
              className="text-danger"
              key={`invalid-feedback-${fieldData.name}-${idx}`}
            >
              {error}
            </small>
          ))
        : submited &&
          !isValid && (
            <small className="text-danger">{validationMessage}</small>
          )}
    </CFormGroup>
  )
}

export default GenericInputGroup
