import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { isNaN } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastOperations } from 'src/state/ducks/toast'
import { ModelApi } from 'src/api'
import { withRouter } from 'react-router-dom'
import { navigationOperations } from 'src/state/ducks/navigation'
import { DjangoRemoteFormPropTypes } from './_types.d.ts'
import DjangoFormContent from './DjangoFormContent'
import { modalFormOperations } from 'src/state/ducks/modalForm'

import './DjangoForm.scss'
import { companySelectors } from 'src/state/ducks/company'
import { isMobile } from './DjangoList'
import PermissionsMiddleware from './PermissionsMiddleware'
import { modalOperations } from 'src/state/ducks/modal'
import ModelLogs from './ModelLogs'

const propTypes = {
  form: DjangoRemoteFormPropTypes,
  objectId: PropTypes.number,
  djangoApp: PropTypes.string,
  djangoModel: PropTypes.string,
  fixedData: PropTypes.object,
  bare: PropTypes.bool,
  shouldRedirectBack: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  redirectBack: PropTypes.func,
  showModalForm: PropTypes.func,
  dismissModalForm: PropTypes.func,
  showPreviousModalForm: PropTypes.func,
  bypassPrefillBlacklist: PropTypes.bool,
}
const defaultProps = {
  shouldRedirectBack: true,
  bypassPrefillBlacklist: false,
}

class DjangoForm extends Component {
  static propTypes = propTypes
  static defaultProps = defaultProps

  constructor(props) {
    super(props)

    this.state = this.initialState
    props.getRef && props.getRef(this)
  }

  get initialState() {
    return {
      data: {},
      modalForm: null, // { djangoApp, djangoModel, objectId }
      submited: false,
      displayData: null,
    }
  }

  get prefillBlacklist() {
    return ['custom_identifier', 'category', 'note', 'status']
  }

  get parsedSearchParams() {
    const parsedParams = new URLSearchParams(window.location.search)
    const existantKeys = [...parsedParams.keys()]
    const arrayFields = ['selectmultiple']

    return Object.entries(this.form.fields || {}).reduce(
      (red, [key, field]) => {
        if (!existantKeys.includes(key)) return red

        const val = parsedParams.get(key)

        if (arrayFields.includes(field.widget.input_type))
          red[key] = parsedParams.getAll(key).map((e) => (isNaN(+e) ? e : +e))
        else if (!isNaN(+val)) red[key] = +val
        else red[key] = val

        return red
      },
      {}
    )
  }

  get data() {
    const { data: propsData } = this.props
    const { data: stateData } = this.state
    const { data: formData } = this.form
    const queryStringData = this.parsedSearchParams

    this.runFieldBlacklist(propsData)
    this.runFieldBlacklist(queryStringData)
    this.runFieldDefaultPreferences(formData)

    return {
      ...formData,
      ...propsData,
      ...queryStringData,
      ...stateData,
      ...this.fixedData,
    }
  }

  get fixedData() {
    const { fixedData = {} } = this.props
    return {
      ...fixedData,
      company: this.selectedCompanyId,
    }
  }

  get form() {
    const { form } = this.props
    const { form: stateForm } = this.state

    return form || stateForm
  }

  get selectedCompanyId() {
    const { selectedCompany } = this.props

    if (selectedCompany) return +selectedCompany.id
    else throw new Error('No company selected')
  }

  get isFormValid() {
    const { fieldsValidation } = this.state

    const form = this.form || { fields: {} }
    const data = this.data
    const requiredFields = Object.entries(form.fields).filter(
      ([_, f]) => f.required
    )

    if (Object.values(fieldsValidation || {}).some((v) => !v)) return false

    return !requiredFields.some(([key]) => {
      return data[key] === undefined || data[key] === null
    })
  }

  get hasEmbbededForm() {
    const { fields } = this.form
    return Object.values(fields || {}).some(
      ({ widget }) => widget.input_type === 'embeddedform'
    )
  }

  get customSuccessRedirect() {
    return new URLSearchParams(window.location.search).get('next')
  }

  runFieldBlacklist(obj) {
    if (this.props.bypassPrefillBlacklist) {
      return obj
    }
    return (
      obj &&
      Object.keys(obj).forEach((key) => {
        if (this.prefillBlacklist.includes(key)) delete obj[key]
      })
    )
  }

  runFieldDefaultPreferences(obj) {
    const { form } = this.state
    if (form) {
      for (const key in form.fields) {
        const { app_name, model_name } = form.fields[key]

        if (app_name && model_name) {
          const pref = window.localStorage.getItem(
            `prevenx/preference/model-default-${model_name}`
          )
          if (!pref) {
            continue
          }

          const prefParsed = +pref
          if (Number.isInteger(prefParsed)) {
            obj[key] = prefParsed
          }
        }
      }
    }
  }

  componentDidMount() {
    const { form } = this.props

    if (!form) this.fetchForm()
  }

  componentDidUpdate(prevProps) {
    const { submit, djangoModel } = this.props

    if (submit && !prevProps.submit) this.handleSubmit()
    if (djangoModel !== prevProps.djangoModel)
      this.setState(this.initialState, this.fetchForm)
  }

  handleCancel = () => {
    const { onClose, history } = this.props
    this.setState(
      { data: {} },
      () => (onClose && onClose()) || (history && history.goBack())
    )
  }

  fetchForm = () => {
    const { djangoApp, djangoModel, objectId, showToast, requestParams } =
      this.props
    const { token } = this.props

    if (!djangoApp || !djangoModel) return
    ModelApi.shared
      .form(token, djangoApp, djangoModel, objectId, requestParams)
      .then(
        ({ data: { form } }) => {
          if (form) this.setState({ form })
          else {
            showToast('Não foi possível encontrar o formulário', {
              appearance: 'error',
            })
            this.handleCancel()
          }
        },
        () => {
          this.setState(
            {
              form: {},
            },
            () => {
              showToast('Erro no carregamento do formulário', {
                appearance: 'error',
              })
              this.handleCancel()
            }
          )
        }
      )
  }

  /**
   * Handle a default feedback for the user
   *
   * @param {bool} status Request status
   * @param {string} customMessage A custom message to display
   */
  handleFeedback = (status, customMessage) => {
    const { showToast } = this.props

    if (status === true)
      showToast(customMessage || 'Registro salvo', { appearance: 'success' })
    else
      showToast(customMessage || 'Não foi possível salvar o registro', {
        appearance: 'error',
      })
  }

  handleRedirectBack = (data) => {
    const {
      djangoApp,
      djangoModel,
      objectId,
      redirectBack,
      shouldRedirectBack,
      navigate,
      showToast,
    } = this.props

    if (this.customSuccessRedirect) {
      const absolutePathRegex = new RegExp(/^(?:[a-z]+:)?\/\//i)
      const params = {
        pk: data.pk,
      }

      if (absolutePathRegex.test(this.customSuccessRedirect))
        return window.location.replace(
          `${this.customSuccessRedirect}?${new URLSearchParams(params)}`
        )
      else return navigate(this.customSuccessRedirect, params)
    }

    if (redirectBack) redirectBack(data)
    else {
      if (shouldRedirectBack) {
        if (this.hasEmbbededForm && !objectId && data.pk) {
          // TODO: Use history.push instead
          navigate(`/${djangoApp}/${djangoModel}/form`, {}, { id: data.pk })
          showToast('Agora você pode cadastrar os itens relacionados')
        } else navigate(`/${djangoApp}/${djangoModel}/list`)
      }
    }
  }

  handleSubmitSuccess = ([success, { data }]) => {
    const { onSubmit, showToast } = this.props
    const { form } = this.state

    if (success) {
      this.setState(
        {
          form: {
            ...form,
            errors: [],
          },
        },
        () => {
          this.handleFeedback(true)
          this.handleRedirectBack(data)
          onSubmit && onSubmit(data, this.data)
        }
      )
    } else {
      this.setState({ form: data.form })
      showToast('Verifique os campos do formulário', {
        appearance: 'warning',
      })
      for (const e of data.form?.non_field_errors || []) {
        showToast(e, { appearance: 'error' }, 10000)
      }
    }
  }

  handleSubmitError = () => {
    this.handleFeedback(false)
  }

  handleSubmit = (force = false) => {
    const { token, onSubmit, djangoApp, djangoModel, objectId, showToast } =
      this.props
    const { displayData } = this.state

    if (djangoApp && djangoModel) {
      if (objectId)
        ModelApi.shared
          .update(token, djangoApp, djangoModel, { id: objectId, ...this.data })
          .then(this.handleSubmitSuccess, this.handleSubmitError)
      else
        ModelApi.shared
          .create(token, djangoApp, djangoModel, {
            ...this.data,
          })
          .then(this.handleSubmitSuccess, this.handleSubmitError)
    } else {
      this.setState({ submited: true }, () => {
        if (this.isFormValid || force)
          onSubmit(
            { ...this.data, company: this.selectedCompanyId },
            this.handleFeedback,
            displayData
          )
        else
          showToast('Verifique os campos do formulário', {
            appearance: 'warn',
          })
      })
    }
  }

  handleFieldChange = (
    value,
    isValid,
    extraData,
    fieldName,
    { widget, label }
  ) => {
    const { fieldsValidation, displayData } = this.state
    this.setState({
      data: {
        ...this.data,
        ...extraData,
        [fieldName]: value,
      },
      fieldsValidation: {
        ...fieldsValidation,
        [fieldName]: isValid,
      },
      displayData: {
        ...displayData,
        ...extraData,
        [label]: widget.input_type === 'selectmultiple' ? value : value,
      },
    })
  }

  handleRelationChange = (
    relatedDjangoApp,
    relatedDjangoModel,
    relatedObjectId,
    data,
    fixedData,
    submitCallback
  ) => {
    const { showModalForm, showPreviousModalForm } = this.props

    showModalForm(
      relatedObjectId,
      relatedDjangoApp,
      relatedDjangoModel,
      data || {},
      fixedData,
      (...data) => {
        submitCallback && submitCallback(...data)
        showPreviousModalForm()
      },
      showPreviousModalForm
    )
  }

  renderBody() {
    const { submited } = this.state
    const {
      token,
      objectId,
      djangoModel,
      djangoApp,
      showRelatedFieldsActions,
    } = this.props

    return (
      <DjangoFormContent
        authToken={token}
        form={this.form}
        data={this.data}
        fixedData={this.fixedData}
        objectId={objectId}
        djangoApp={djangoApp}
        djangoModel={djangoModel}
        submited={submited}
        onFieldChange={this.handleFieldChange}
        onRelationChange={this.handleRelationChange}
        showRelatedFieldsActions={showRelatedFieldsActions}
      />
    )
  }

  render() {
    const {
      djangoApp,
      djangoModel,
      objectId,
      showModal,
      bare,
      showRelatedFieldsActions = true,
    } = this.props
    const { modalForm } = this.state

    const form = this.form

    if (!form) return null

    const { title } = form

    if (bare) return this.renderBody()

    return (
      <>
        <CCard
          style={{
            margin: '0 -15px', // Compensate default layout padding
          }}
        >
          <CCardHeader>{title}</CCardHeader>
          <CCardBody>{this.renderBody()}</CCardBody>
          <CCardFooter align="right">
            <PermissionsMiddleware
              djangoApp={'users'}
              djangoModel={'prevenlogentry'}
              permissionType="can_view"
            >
              {objectId && (
                <CButton
                  type="submit"
                  size="sm"
                  color="dark"
                  variant="outline"
                  block={isMobile.any()}
                  onClick={() =>
                    showModal({
                      title: 'Histórico',
                      body: (
                        <ModelLogs
                          objectId={objectId}
                          djangoApp={djangoApp}
                          djangoModel={djangoModel}
                        />
                      ),
                    })
                  }
                >
                  Logs
                </CButton>
              )}
              &nbsp;
            </PermissionsMiddleware>
            {!isMobile.any() && (
              <CButton
                type="reset"
                size="sm"
                color="danger"
                variant="outline"
                onClick={this.handleCancel}
              >
                <CIcon name="cil-ban" /> Cancelar
              </CButton>
            )}
            &nbsp;
            <CButton
              type="submit"
              size="sm"
              color="primary"
              block={isMobile.any()}
              onClick={this.handleSubmit}
            >
              <CIcon name="cil-check" /> Salvar
            </CButton>
          </CCardFooter>
        </CCard>
        <br />
        {modalForm && (
          <DjangoFormModal
            {...modalForm}
            showRelatedFieldsActions={showRelatedFieldsActions}
            onClose={() => this.setState({ modalForm: null })}
            redirectBack={() => {
              const { submitCallback } = modalForm
              submitCallback && submitCallback()
              this.setState({ modalForm: null }, () => this.fetchForm())
            }}
          />
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  const {
    auth: { token },
  } = state

  return { token, selectedCompany: companySelectors.selectedCompany(state) }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navigate: navigationOperations.navigate,
      showToast: toastOperations.show,
      showModal: modalOperations.show,
      showModalForm: modalFormOperations.show,
      showPreviousModalForm: modalFormOperations.showPrevious,
      dismissModalForm: modalFormOperations.dismiss,
    },
    dispatch
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DjangoForm)
)

export const DjangoFormModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class extends DjangoForm {
    static propTypes = {
      ...propTypes,
      modalSize: PropTypes.string,
    }

    static defaultProps = {
      ...defaultProps,
      modalSize: 'md',
    }

    state = {
      show: true,
    }

    render() {
      const {
        token,
        onClose,
        objectId,
        djangoModel,
        djangoApp,
        modalSize,
        closeOnBackdrop = true,
        showRelatedFieldsActions = true,
      } = this.props
      const { submited } = this.state

      const form = this.form

      if (!form) return null

      const { title = 'Formulário' } = form

      const handleClose = () => {
        if (window.confirm('deseja fechar?')) return onClose()
        this.setState({ show: false }, () =>
          setTimeout(() => this.setState({ show: true }), 10)
        )
      }

      console.log(this.state.show)

      return (
        <div className="django-form-modal">
          <CModal
            show={this.state.show}
            onClose={handleClose}
            style={{ textAlign: 'left' }}
            size={modalSize}
            closeOnBackdrop={closeOnBackdrop}
          >
            <CModalHeader>
              {title.toTitleCase()}
              <CIcon
                name="cil-x"
                style={{ cursor: 'pointer' }}
                onClick={this.handleCancel}
              />
            </CModalHeader>
            <CModalBody>
              <DjangoFormContent
                authToken={token}
                form={form}
                data={this.data}
                fixedData={this.fixedData}
                objectId={objectId}
                djangoApp={djangoApp}
                djangoModel={djangoModel}
                submited={submited}
                onFieldChange={this.handleFieldChange}
                onRelationChange={this.handleRelationChange}
                showRelatedFieldsActions={showRelatedFieldsActions}
              />
            </CModalBody>
            <CModalFooter>
              <CButton
                type="reset"
                size="sm"
                color="danger"
                variant="outline"
                onClick={this.handleCancel}
              >
                <CIcon name="cil-ban" /> Cancelar
              </CButton>
              &nbsp;
              <CButton
                type="submit"
                size="sm"
                color="primary"
                onClick={this.handleSubmit}
              >
                <CIcon name="cil-check" /> Salvar
              </CButton>
            </CModalFooter>
          </CModal>
        </div>
      )
    }
  }
)
