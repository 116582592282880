import reducer from './reducers'

import * as loaderOperations from './operations'
import * as loaderTypes from './types'
import * as loaderSelectors from './selectors'
import * as loaderHooks from './hooks'

export { loaderOperations, loaderTypes, loaderSelectors, loaderHooks }

export default reducer
