import * as types from "./types";

/**
 * Simplifies the navigation across the components
 *
 * @param {string} route Desired route
 * @param {string} params Params to the route
 */
export const navigate = (route, params={}, state={}) => (dispatch) =>
  dispatch({
    type: types.NAVIGATE,
    payload: () => {
      dispatch({ type: types.CLEAR_NAVIGATION });
      return {
        route,
        params,
        state
      };
    },
  });
