import React from 'react'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import { isMobile } from './DjangoList'

const getHumanizedIntervalMultiplier = (value) => {
  for (const opt of [
    { label: 'Dias', multiplier: 86400 },
    { label: 'Horas', multiplier: 3600 },
    { label: 'Minutos', multiplier: 60 },
    { label: 'Segundos', multiplier: 1 },
  ]) {
    if (value % opt.multiplier === 0) return opt
  }
}

export const getHumanizedInterval = (value) => {
  const { multiplier, label } = getHumanizedIntervalMultiplier(value)
  return `${value / multiplier} ${label}`
}

export const statusColumn = ({ status }) => {
  if (typeof status === 'boolean') {
    status = status ? 'ativo' : 'inativo'
  }
  return (
    <td>
      <span
        style={{
          whiteSpace: 'nowrap',
          background:
            status.toLowerCase() === 'ativo'
              ? 'rgba(0, 0, 21, 0.05)'
              : 'transparent',
          opacity: status.toLowerCase() === 'ativo' ? '1' : '.5',
          color: status.toLowerCase() === 'ativo' ? 'unset' : 'red',
          padding: '.1rem .5rem',
          borderRadius: '2rem',
          border:
            status.toLowerCase() === 'ativo'
              ? '2px solid white'
              : 'transparent',
          display: 'inline-block',
        }}
      >
        <CIcon
          name="cil-power-standby"
          style={
            status.toLowerCase() === 'ativo'
              ? {
                  marginRight: '.25rem',
                  marginTop: '-.25rem',
                  color: 'green',
                }
              : {
                  marginRight: '.25rem',
                  marginTop: '-.25rem',
                  color: 'red',
                }
          }
        />
        {!isMobile.any() && status}
      </span>
    </td>
  )
}

export const humanizedInterval = (field) => (
  <td>{getHumanizedInterval(field)}</td>
)

export const formattedDate = (field, props = {}, renderTd = true) => {
  let formatted
  try {
    if (field) formatted = moment(field).format('DD/MM/YYYY HH:mm:ss')
  } catch (error) {
    console.error(error)
  }
  if (!renderTd) return formatted || 'N/E'
  return <td {...props}>{formatted || 'N/E'}</td>
}

export const emptySafe = (field) => {
  return (
    <td>
      {field ? String(field).toTitleCase() : <span className="muted">N/E</span>}
    </td>
  )
}
