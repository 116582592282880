import { CButton, CInputFile } from '@coreui/react'
import React, { useCallback, useState } from 'react'
import GenericInputGroup from './GenericInputGroup'
import './FileInput.scss'
import { useDispatch } from 'react-redux'
import { modalOperations } from 'src/state/ducks/modal'
import Camera from '../Camera'
import { isMobile } from '../DjangoList'
import CIcon from '@coreui/icons-react'
import DocumentCard from 'src/components/DocumentCard'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const CameraModalBody = ({ onSubmit, onClose }) => {
  // TODO: Implement image confirmation
  return <Camera onShut={onSubmit} onClose={onClose} />
}

const FileInput = ({
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
}) => {
  const dispatch = useDispatch()
  const [image, setImage] = useState()
  const openCamera = useCallback(() => {
    dispatch(
      modalOperations.show({
        size: 'lg',
        body: () => (
          <CameraModalBody
            onSubmit={(content) => {
              onChange({
                name: 'picture.jpg',
                size: content.length,
                type: 'image/jpeg',
                content,
              })
              setImage(content)
              dispatch(modalOperations.dismiss())
            }}
            onClose={() => {
              dispatch(modalOperations.dismiss())
            }}
          />
        ),
      })
    )
  }, [dispatch, onChange])

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile.any() ? 'column' : 'row',
          alignItems: 'center',
        }}
      >
        {isMobile.any() && (
          <CButton
            size="lg"
            color="primary"
            onClick={openCamera}
            block={isMobile.any()}
            style={{ margin: '1rem' }}
          >
            <CIcon name="cil-camera" />
            &nbsp; Abrir camera
          </CButton>
        )}
        <CInputFile
          onChange={({ target: { files = [] } }) => {
            const file = files[0]

            if (file) {
              toBase64(file).then(
                (content) => {
                  onChange({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    content,
                  })
                },
                () => {
                  // TODO: Show error toast
                }
              )
            } else {
              onChange(null)
            }
          }}
          style={{ flex: 1, margin: '1rem' }}
        />
        {image && (
          <img
            alt="file field"
            src={image}
            style={{
              height: '100px',
              borderRadius: '5px',
              marginRight: '1rem',
            }}
          />
        )}
        {fieldData.initial && <DocumentCard file={fieldData.initial} />}
      </div>
    </GenericInputGroup>
  )
}

export default FileInput
