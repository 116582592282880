import React from 'react'
import PropTypes from 'prop-types'
import { CLink } from '@coreui/react'
import { isMobile } from 'src/reusable/DjangoList'
import CIcon from '@coreui/icons-react'

const ActionButton = ({ label, iconName, onClick, noMargin, style }) => (
  <CLink
    onClick={onClick}
    style={{
      marginLeft: noMargin ? 0 : '1rem',
      whiteSpace: 'nowrap',
      ...(style || {}),
    }}
  >
    {iconName && <CIcon name={iconName} style={{ marginRight: '5px' }} />}
    {!isMobile.any() && label}
  </CLink>
)

ActionButton.propTypes = {
  label: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
}

export default ActionButton
