import store from '../store'
import PropTypes from 'prop-types'
import djangoApps from './_apps'
import djangoModels from './_models'
import permissionTypes from './_permissionTypes'
import djangoPermissions from './_permissions'
import { useSelector } from 'react-redux'

/**
 * Check if user has some permission
 *
 * @param {string} djangoApp Django app name
 * @param {string} djangoModel Django model name
 * @param {string} permissionType Django permission type
 */
export const testPermission = (djangoApp, djangoModel, permissionType) => {
  const {
    auth: { userInfo },
  } = store.getState()

  const desiredPermission = getDesiredPermission(
    djangoApp,
    djangoModel,
    permissionType
  )

  return userInfo?.permissions?.includes(desiredPermission) || false
}

/**
 * Check if user is superuser
 */
export const isSuperuser = () => {
  const {
    auth: { userInfo },
  } = store.getState()
  return !!userInfo?.is_superuser
}

const getDesiredPermission = (djangoApp, djangoModel, permissionType) =>
  djangoPermissions?.[djangoApp.replace(/-/g, '')]?.[
    djangoModel.replace(/-/g, '')
  ]?.[permissionType]

const PermissionsMiddleware = ({
  djangoApp,
  djangoModel,
  permissionType,
  children,
}) => {
  const userInfo = useSelector(({ auth: { userInfo } }) => userInfo)

  const desiredPermission = getDesiredPermission(
    djangoApp,
    djangoModel,
    permissionType
  )

  return userInfo?.permissions?.includes(desiredPermission) ? children : null
}

PermissionsMiddleware.propTypes = {
  djangoApp: PropTypes.oneOf(djangoApps),
  djangoModel: PropTypes.oneOf(djangoModels),
  permissionType: PropTypes.oneOf(permissionTypes),
}

export default PermissionsMiddleware
