import * as types from './types'
import { AuthApi, ModelApi } from 'src/api'
import { loaderOperations } from 'src/state/ducks/loader'
import Firebase from 'src/firebase'
import * as Sentry from '@sentry/react'

export const message = (dispatch, type, content) =>
  dispatch({
    type: types.MESSAGE,
    payload: () => {
      dispatch({ type: types.DISMISS_MESSAGE })
      return {
        type,
        content,
      }
    },
  })

export const login = (
  username,
  password,
  databaseId,
  successCallback = null,
  errorCallback = null
) => (dispatch) => {
  const loadingAction = 'prevenX/login'
  dispatch(loaderOperations.start(loadingAction))
  AuthApi.shared.login(username, password, databaseId).then(
    ({ data }) => {
      dispatch(loaderOperations.stop(loadingAction))
      dispatch({
        type: types.LOGIN,
        payload: data.token,
      })
      successCallback && successCallback(data)
    },
    (error) => {
      dispatch(loaderOperations.stop(loadingAction))
      errorCallback && errorCallback(error)
    }
  )
}

export const getUserInfo = (token) => (dispatch) => {
  AuthApi.shared.getUserInfo(token).then(
    (data) => {
      Sentry.setUser({
        ...(data.data || {}),
        databaseId: ModelApi.shared.databaseId,
        company: ModelApi.shared.companyHeader,
      })
      Firebase.setUser(
        ModelApi.shared.databaseId,
        ModelApi.shared.companyHeader,
        data.data
      )
      if (window.fcWidget) {
        const { first_name, last_name, email, username } = data.data
        window.fcWidget.user.setProperties({
          externalId: username,
          firstName: first_name,
          lastName: last_name,
          email,
          // address: 'abc'
          // database_id: 'abc', // ModelApi.shared.databaseId,
          // company_info: 'abc', // ModelApi.shared.companyHeader,
        })
      }
      if (window.FreshworksWidget) {
        const { first_name, last_name, email } = data.data
        // eslint-disable-next-line
        FreshworksWidget('identify', 'ticketForm', {
          name: [first_name, last_name].filter(Boolean).join(' '),
          email:
            email &&
            !['autogenerated'].some((ignoreStr) => email.includes(ignoreStr))
              ? email
              : '',
        })
        // eslint-disable-next-line
        FreshworksWidget('prefill', 'ticketForm', {
          subject: 'Duvida sobre o preven',
          description: 'Oi, gostaria de uma ajuda aqui',
          // priority: 1,
          // status: 2,
          // group_id: 69000631384, // Preven group id
          // type: 'Question',
          // custom_fields: {
          //   prev_database_id: ModelApi.shared.databaseId,
          //   prev_company: ModelApi.shared.companyHeader,
          // },
        })
      }

      dispatch({
        type: types.GET_USER_INFO_SUCCESS,
        payload: data || '',
      })
    },
    (error) => {
      dispatch({
        type: types.GET_USER_INFO_ERROR,
        payload: error,
      })
    }
  )
}
export const logout = () => (dispatch) => dispatch({ type: types.LOGOUT })

export const verifyToken = (token) => (dispatch) => {
  // TODO: Implement backend request to verify the token
  if (token)
    dispatch({
      type: types.LOGIN,
      payload: token,
    })
}

export const resetPassword = () => () => {}
