import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Timeline from '@material-ui/lab/Timeline'
import { ModelApi } from 'src/api'
import { useToken } from 'src/state/ducks/auth/hooks'
import TimelineEvent from 'src/components/TimelineEvent'

const ModelLogs = ({ djangoModel, djangoApp, objectId }) => {
  const token = useToken()
  const [data, setData] = useState(null)

  useEffect(
    () => {
      ModelApi.shared
        ._get(
          ModelApi.shared._getFullPath(
            `/utilities/api/logs/${djangoApp}/${djangoModel}/${objectId}`
          ),
          {
            authToken: token,
            params: {},
          }
        )
        .then(({ data }) => setData(data))
    }, // eslint-disable-next-line
    []
  )

  console.log(data)

  return (
    <Timeline align="left" style={{ margin: 0 }}>
      {data?.logs.map((log) => (
        <TimelineEvent
          date={moment(log.action_time).format('DD/MM/YYYY HH:mm')}
          label={
            <>
              O usuário <b>{log.user}</b> realizou <b>"{log.action}"</b>
            </>
          }
        />
      ))}
    </Timeline>
  )
}

export default ModelLogs
