import * as types from './types';

const reducer = (state = null, { type, payload }) => {
  switch (type) {
    case types.NAVIGATE:
      return payload;
    case types.CLEAR_NAVIGATION:
      return null
    default:
      return state;
  }
};

export default reducer;
