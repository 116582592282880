import CIcon from '@coreui/icons-react'
import { CAlert } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ModelApi } from 'src/api'
import DocumentCard from 'src/components/DocumentCard'
import { authSelectors } from 'src/state/ducks/auth'
import GenericInputGroup from './DjangoFormInputs/GenericInputGroup'

export const DocumentInput = ({
  fieldData,
  fieldName,
  fieldErrors,
  value,
  submited,
  djangoModel,
  objectId,
  filters,
  onRelationChange,
}) => {
  const token = useSelector(authSelectors.token)
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState(null)

  const { data } = listData || {}

  const modelFieldName = ModelApi.getFieldNameFromDjangoModel(djangoModel)

  useEffect(() => {
    if (!listData && !loading && objectId) {
      setLoading(true)
      ModelApi.shared
        .list(
          token,
          fieldData.app_name,
          fieldData.model_name,
          {
            ...filters,
            [`${modelFieldName}_id`]: objectId,
          },
          undefined,
          undefined,
          undefined,
          undefined,
          false
        )
        .then(
          ({ data }) => {
            setListData(data)
            setLoading(false)
          },
          (e) => {
            console.error(e)
            setListData([])
            setLoading(false)
          }
        )
    }
    // eslint-disable-next-line
  }, [listData, loading, objectId])

  if (!objectId)
    return (
      <GenericInputGroup
        fieldName={fieldName}
        fieldData={fieldData || {}}
        fieldErrors={fieldErrors}
        value={value}
        submited={submited}
      >
        <CAlert color="info">
          <CIcon name="cil-info" />
          &nbsp;Salve o registro para poder vincular {fieldData.label}
        </CAlert>
      </GenericInputGroup>
    )

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData || {}}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      {loading && <p className="text-center muted">Carregando</p>}
      {listData && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'auto',
            paddingBottom: '.3rem',
          }}
        >
          <div
            onClick={() =>
              onRelationChange(
                fieldData.app_name,
                fieldData.model_name,
                undefined,
                {},
                {
                  [modelFieldName]: objectId,
                },
                () => setListData(null)
              )
            }
            className="add-file-card"
          >
            <span>+</span>
            <span>Adicionar</span>
          </div>
          {(data || []).map((dc) => (
            <DocumentCard
              onEditClick={() =>
                onRelationChange(
                  fieldData.app_name,
                  fieldData.model_name,
                  dc.id,
                  {},
                  {
                    [modelFieldName]: objectId,
                  },
                  () => setListData(null)
                )
              }
              {...dc}
            />
          ))}
        </div>
      )}
    </GenericInputGroup>
  )
}

export default DocumentInput
