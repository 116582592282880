import BasePrevenApiClient from './baseApi'
/* eslint-disable-next-line no-unused-vars */
import { loaderOperations } from 'src/state/ducks/loader'
import { toastOperations } from 'src/state/ducks/toast'
import qs from 'qs'

/**
 * Handle model operations on django api
 */
class ModelApi extends BasePrevenApiClient {
  /**
   * Returns a shared api instance
   *
   * @returns {ModelApi} Api shared instance
   */
  static get shared() {
    return super.shared
  }

  static getFieldNameFromDjangoModel(djangoModel) {
    if (
      [
        'maintenance-order-related',
        'maintenance-order-employee-attendance-related',
      ].includes(djangoModel)
    ) {
      return 'maintenance_order'
    } else {
      return djangoModel ? djangoModel.split('-').join('_') : ''
    }
  }

  static formatFormErrors(errors) {
    return Object.entries(errors)
      .map(
        ([key, value]) =>
          `${key.split('_').join(' ').toTitleCase()}: ${value.join('')}\n`
      )
      .join('\n')
  }

  /**
   * Build the path string for the desired request
   *
   * @param {string} app Django app name
   * @param {string} model Django model name
   *
   * @returns {AxiosPromise}
   */
  _getListUrl(app, model) {
    return this._getFullPath(`/${app}/api/list/${model}`)
  }

  /**
   * Handle a list request to django api for the desired model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {object} params Request parameters
   * @param {number} page Current pagination page (default 1)
   * @param {number} limit Pagination limit (default 10)
   * @param {string} orderBy List order (default id)
   * @param {string} textFilter Request parameters
   *
   * @returns {AxiosPromise}
   */
  list = (
    authToken,
    app,
    model,
    params = {},
    page = 1,
    limit = 10,
    orderBy = 'id',
    textFilter = null,
    shouldShowLoader = true,
    shouldCache = true
  ) => {
    const path = this._getListUrl(app, model)

    for (const key in params) {
      if (key === undefined || key === 'undefined') delete params[key]
    }

    shouldShowLoader && this._dispatch(loaderOperations.start(path))
    return this._get(path, {
      authToken,
      params: {
        ...params,
        paginate_by: limit,
        page,
        order_by: orderBy,
        text_filter: textFilter,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }).then(
      (data) => {
        shouldShowLoader && this._dispatch(loaderOperations.stop(path))
        !shouldCache && this._clearMemos()
        return data
      },
      (error) => {
        shouldShowLoader && this._dispatch(loaderOperations.stop(path))
        this._clearMemos()
        throw error
      }
    )
  }

  /**
   * Handle a list request to django api for the desired model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {number} objectId Desired object id
   *
   * @returns {AxiosPromise}
   */
  get = (authToken, app, model, objectId) => {
    return this.list(authToken, app, model, { id: objectId }, 1, 1).then(
      ({ data }) => data.data && data.data[0]
    )
  }
  /**
   * Handle a list request to django api for the desired model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {number} objectId Request parameters
   * @param {string} fieldName Model field name
   *
   * @returns {AxiosPromise}
   */
  listLoggedField(authToken, app, model, objectId, fieldName) {
    const path = `${this._getListUrl(
      app,
      model
    )}/${this.getLoggedFieldModelName(fieldName)}`

    this._dispatch(loaderOperations.start(path))
    return this._get(path, {
      authToken,
      params: {
        origin_id: objectId,
        order_by: '-datetime',
      },
    }).then(
      (data) => {
        this._dispatch(loaderOperations.stop(path))
        return data
      },
      (error) => {
        this._dispatch(loaderOperations.stop(path))
        this._clearMemos()
        throw error
      }
    )
  }

  getLoggedFieldModelName(fieldName) {
    return fieldName.split('_').join('-')
  }

  tryJsonParse(inputString) {
    try {
      return JSON.parse(inputString)
    } catch (error) {
      console.warn(inputString, error)
      return {}
    }
  }

  /**
   * Handle a list request to django api for the desired model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {string} textFilter Request parameters
   * @param {object} selectedOptions Curently selected options
   * @param {object} additionalFilters Additional filters for the model list API
   *
   * @returns {AxiosPromise}
   */
  listSelectOptions(
    authToken,
    app,
    model,
    textFilter = null,
    selectedOptions = [],
    limit = undefined,
    modelfilter = '',
    additionalFilters = null
  ) {
    const path = this._getListUrl(app, model)
    const include = selectedOptions

    // this._dispatch(loaderOperations.start(path))
    return this._get(path, {
      authToken,
      params: {
        select_return: true,
        text_filter: textFilter,
        include,
        paginate_by: limit,
        ...this.tryJsonParse(modelfilter),
        ...(additionalFilters || {}),
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }).then(
      (data) => {
        // this._dispatch(loaderOperations.stop(path))
        return data
      },
      (error) => {
        // this._dispatch(loaderOperations.stop(path))
        this._clearMemos()
        throw error
      }
    )
  }

  /**
   * Build the path string for the desired request
   *
   * @param {string} app Django app name
   * @param {string} model Django model name
   *
   * @returns {AxiosPromise}
   */
  _getFormUrl(app, model, id = undefined) {
    return this._getFullPath(`/${app}/api/form/${model}${id ? `/${id}` : ''}`)
  }

  /**
   * Handle a form request to django api for the desired model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   *
   * @returns {AxiosPromise}
   */
  form(authToken, app, model, id = undefined, requestParams = {}) {
    const path = this._getFormUrl(app, model, id)

    this._dispatch(loaderOperations.start(path))
    return this._get(path, { authToken, params: requestParams }).then(
      (data) => {
        this._dispatch(loaderOperations.stop(path))
        return data
      },
      (error) => {
        this._dispatch(loaderOperations.stop(path))
        this._clearMemos()
        throw error
      }
    )
  }

  /**
   * Returns an status for the given request
   *
   * @param {AxiosResponse} response
   */
  static validateFormResponse(response) {
    try {
      const success = !(
        Object.keys((response.data.form && response.data.form.errors) || {})
          .length > 0
      )
      return [success, response]
    } catch (error) {
      console.error(error)
      return [false, response]
    }
  }

  /**
   * Handle the creation of the model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {object} data Data for the created object
   *
   * @returns {AxiosPromise}
   */
  create(authToken, app, model, data) {
    const path = this._getFormUrl(app, model)

    this._dispatch(loaderOperations.start(path))
    return this._post(path, data, { authToken })
      .then(ModelApi.validateFormResponse)
      .then(
        (data) => {
          this._dispatch(loaderOperations.stop(path))
          return data
        },
        (error) => {
          this._dispatch(loaderOperations.stop(path))
          this._clearMemos()
          throw error
        }
      )
  }

  /**
   * Handle the update of the model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {object} data Data for the updated object
   *
   * @throws {Error}
   *
   * @returns {AxiosPromise}
   */
  update(authToken, app, model, data) {
    if (!data.id) throw new Error("Can't find object id.")

    const path = this._getFormUrl(app, model, data.id)

    this._dispatch(loaderOperations.start(path))
    return this._put(path, data, { authToken })
      .then(ModelApi.validateFormResponse)
      .then(
        (data) => {
          this._dispatch(loaderOperations.stop(path))
          return data
        },
        (error) => {
          this._dispatch(loaderOperations.stop(path))
          this._clearMemos()
          throw error
        }
      )
  }

  /**
   * Handle the deletion of the model
   *
   * @param {string} authToken User's auth token
   * @param {string} app Django app name
   * @param {string} model Django model name
   * @param {number} id Model id
   * @param {object} params Querystring params
   *
   * @throws {Error}
   *
   * @returns {AxiosPromise}
   */
  delete(authToken, app, model, id, params) {
    if (!id) throw new Error("Can't find object id.")

    const path = this._getFormUrl(app, model, id)

    this._dispatch(loaderOperations.start(path))
    return this._delete(path, { authToken, params }).then(
      (data) => {
        this._dispatch(loaderOperations.stop(path))
        this._dispatch(
          toastOperations.show('Registro excluído com sucesso', {
            appearance: 'success',
          })
        )
        return data
      },
      (error) => {
        this._dispatch(loaderOperations.stop(path))
        this._dispatch(
          toastOperations.show('Não foi possível excluir o registro', {
            appearance: 'error',
          })
        )
        this._clearMemos()
        throw error
      }
    )
  }
}

export default ModelApi
