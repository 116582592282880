import axios from 'axios'
/* eslint-disable no-unused-vars */
import { companySelectors } from 'src/state/ducks/company'
import { toastOperations } from 'src/state/ducks/toast'
import memoizee from 'memoizee'
import store from 'src/store'

export const PROTOCOL = process.env.REACT_APP_DJANGO_BACKEND_PROTOCOL || 'http'
export const BASE_URL = process.env.REACT_APP_DJANGO_BACKEND_URL
export const BASE_PATH = process.env.REACT_APP_DJANGO_BACKEND_PATH || ''

export const DEFAULT_API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const API_DATETIME_WITH_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
export const DEFAULT_API_DATE_FORMAT = 'YYYY-MM-DD'
/**
 * Sumary.
 *
 * Description.
 *
 * @param {axios} httpClient An axios instance to handle the Http requests
 * @param {string} databaseId An database id to identify for the backend wich DB are we connecting
 */
class BasePrevenApiClient {
  /**
   * Shared api instance
   */
  static _shared = null
  static _dispatch = () => null

  _httpClient = axios

  constructor(httpClient, databaseId, config) {
    this._httpClient = httpClient
    if (!this._httpClient.interceptors.response.handlers.length)
      this._httpClient.interceptors.response.use(
        (response) => response,
        (error) => {
          this._clearMemos()
          if (error.message === 'Network Error') {
            if (window.navigator.onLine) {
              this._dispatch(
                toastOperations.show(
                  'Falha na conexão, serviços indisponíveis',
                  { appearance: 'warning' }
                )
              )
              // TODO: Redirect user to service error waiting page
            } else {
              this._dispatch(
                toastOperations.show('Falha na conexão, você está offline', {
                  appearance: 'warning',
                })
              )
              // TODO: Redirect user to offline waiting page
            }
            return Promise.reject(error)
          } else if (error.response && error.response.status === 401) {
            localStorage.clear()
            window.location.href = '/'
          } else {
            if (error.response && error.response.data) {
              const { message } = error.response.data

              if (message) {
                this._dispatch(
                  toastOperations.show(message, { appearance: 'error' }, 10000)
                )
              }
            }
            return Promise.reject(error)
          }
        }
      )

    Object.assign(this, config)

    if (!this._httpClient)
      throw new Error(
        'Api client cannot be in instantiated without a httpClient.'
      )

    if (process.env.NODE_ENV !== 'development')
      this._get = memoizee(this._get, {
        normalizer: JSON.stringify,
        max: 20,
        maxAge: 30 * 60 * 1000,
      })
  }

  /**
   * Returns redux a shared dispatcher
   *
   * @returns {Dispatch}
   */
  get _dispatch() {
    return BasePrevenApiClient._dispatch
  }

  /**
   * Returns a shared api instance
   *
   * @returns {BasePrevenApiClient} Api shared instance
   */
  static get shared() {
    if (this._shared === null) this._shared = new this(axios)
    return this._shared
  }

  /**
   * Returns the Django Admin url
   */
  static get adminUrl() {
    return `${PROTOCOL}://${BASE_URL}${BASE_PATH}/admin/`
  }

  /**
   * Returns a full Django Media url
   */
  static getMediaSrc(path) {
    const selectedCompany = this.selectedCompany

    if (selectedCompany) return `${selectedCompany.media_url}/${path}`
    else return `${PROTOCOL}://${BASE_URL}${BASE_PATH}/media/${path}` // TODO: Create a 404 screen
  }

  get databaseId() {
    return localStorage.getItem('databaseId')
  }

  static get selectedCompany() {
    const selectedCompany = companySelectors.selectedCompany(store.getState())

    return selectedCompany || null
  }

  get selectedCompany() {
    return BasePrevenApiClient.selectedCompany
  }

  get companyId() {
    const selectedCompany = this.selectedCompany

    if (selectedCompany) return selectedCompany.id
    else return undefined
  }

  get companyHeader() {
    const selectedCompany = this.selectedCompany

    if (selectedCompany) return `${+selectedCompany.id}/${selectedCompany.name}`
    else return undefined
  }

  set databaseId(value) {
    localStorage.setItem('databaseId', value)
  }

  _clearMemos() {
    try {
      this._get.clear()
    } catch (error) {
      console.warn('Unable to clear memos')
    }
  }

  /**
   *
   * @param {string} path The simplified path to be joined with other common stuff
   * @param {object} params The url querystring params
   */
  _getFullPath(path, params) {
    const parsedPath = path.startsWith('/') ? path : `/${path}`

    let parsedParams = ''
    if (params) parsedParams = Object.buildQueryString(params || {})

    return `${PROTOCOL}://${BASE_URL}${BASE_PATH}/${this.databaseId}${parsedPath}${parsedParams}`
  }

  /**
   * Returns an headers object with the auth token
   *
   * @param {string} token User's auth token
   */
  _getAuthorizationHeader(token) {
    return { authorization: `Token ${token}` }
  }

  /**
   * Return an object with all the common headers
   *
   * @param {string} token User's auth token
   */
  _getHeaders(token, headers = {}) {
    if (process.env.REACT_APP_COOKIE_SESSION_ENABLED) {
      const csrf = document.cookie
        .split('; ')
        .map((c) => c.split('='))
        .find(([key]) => key === 'csrftoken')?.[1]
      if (csrf) {
        Object.assign(headers, {
          'X-CSRFToken': csrf,
        })
      }
    } else {
      if (token) Object.assign(headers, this._getAuthorizationHeader(token))
    }

    if (this.databaseId)
      Object.assign(headers, {
        Database: this.databaseId,
      })

    if (this.companyHeader)
      Object.assign(headers, {
        Company: this.companyHeader,
      })

    return headers
  }

  /**
   * Handle a http request
   *
   * @param {string} method Http request method
   * @param {object} config Axios config object
   */
  _request(method = 'GET', config = {}) {
    if (method !== 'GET') this._clearMemos()
    return this._httpClient.request({
      ...config,
      method,
      headers: this._getHeaders(config.authToken, config.headers),
      withCredentials: process.env.REACT_APP_COOKIE_SESSION_ENABLED,
    })
  }

  /**
   * Handle an http GET request
   *
   * @param {string} url Request url
   * @param {object} config Axios config object
   */
  _get = (url, config = {}) => {
    return this._request('GET', {
      url,
      ...config,
    })
  }

  /**
   * Handle an http POST request
   *
   * @param {string} url Request url
   * @param {object} data Request data
   * @param {object} config Axios config object
   */
  _post(url, data = {}, config = {}) {
    return this._request('POST', {
      url,
      data,
      ...config,
    })
  }

  /**
   * Handle an http PUT request
   *
   * @param {string} url Request url
   * @param {object} data Request data
   * @param {object} config Axios config object
   */
  _put(url, data = {}, config = {}) {
    return this._request('PUT', {
      url,
      data,
      ...config,
    })
  }

  /**
   * Handle an http DELETE request
   *
   * @param {string} url Request url
   * @param {object} config Axios config object
   */
  _delete(url, config = {}) {
    return this._request('DELETE', {
      url,
      ...config,
    })
  }
}

export default BasePrevenApiClient
