import * as types from './types';

const initialState = {
  actions: [],
  refreshing: [],
};

const reducer = (state = initialState, { type, payload }) => {
  const { actions, refreshing } = state;

  switch (type) {
    case types.START:
      return {
        ...state,
        actions: [...actions, payload.action],
      };
    case types.STOP:
      return {
        ...state,
        actions: actions.filter((action) => action.name !== payload.name),
      };
    case types.REFRESH_START:
      return {
        ...state,
        refreshing: [...refreshing, payload.refreshAction],
      };
    case types.REFRESH_STOP:
      return {
        ...state,
        refreshing: refreshing.filter(
          (refresh) => refresh !== payload.refreshAction
        ),
      };
    default:
      return state;
  }
};

export default reducer;
