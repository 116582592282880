import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

export const defaultMaterialTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#2a1ab9',
      },
      secondary: {
        main: '#39f',
      },
    },
  },
  ptBR
)
