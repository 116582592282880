import CIcon from '@coreui/icons-react'
import React, { useRef } from 'react'
import Webcam from 'react-webcam'
import './Camera.scss'
import { isMobile } from './DjangoList'

export const CloseButton = (props) => (
  <button {...props}>
    <CIcon name="cil-arrow-left" style={{ width: '30px', height: '30px' }} />
  </button>
)

const Camera = ({ onShut, onClose }) => {
  // TODO: Implement camera choices (https://www.npmjs.com/package/react-webcam)
  const ref = useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = ref.current.getScreenshot()
    onShut(imageSrc)
  }, [ref, onShut])

  const width = isMobile.any()
    ? window.screen.width
    : Math.min(window.screen.width / 2, 780)
  const height = isMobile.any() ? window.screen.height : width * 0.5625

  return (
    <div className="webcam-container">
      {onClose && <CloseButton className="back" onClick={onClose} />}
      <Webcam
        ref={ref}
        audio={false}
        width={width}
        height={height}
        screenshotFormat="image/jpeg"
        style={{ width: '100%', height: '100%' /* , objectFit: 'cover' */ }}
        videoConstraints={{
          width,
          height,
          facingMode: 'environment',
        }}
      />
      <button className="shutter" onClick={capture} />
    </div>
  )
}

export default Camera
