import React from 'react'
import './activity-indicator.scss'

const ActivityIndicator = () => {
  return (
    <div className="activity-indicator-container">
      <div className="loader">Loading...</div>
    </div>
  )
}
export default ActivityIndicator
