import { parseJson } from './reducers'
import * as types from './types'

export const selectedCompany = ({ company: { selected, list } }) => {
  if (selected) {
    return list?.find((i) => i.id === selected.id)
  } else {
    const stored = parseJson(localStorage.getItem(types.SELECT))
    if (stored) {
      return list?.find((i) => i.id === stored.id)
    } else {
      if ((list || []).length) {
        return list[0]
      }
    }
    return null
  }
}
