import React, { useCallback } from 'react'
import { useModal } from 'src/state/ducks/modal/hooks'

const useDocumentationModal = () => {
  const showModal = useModal()

  const showDocs = useCallback(
    (path) => {
      showModal({
        title: null,
        size: 'lg',
        body: (
          <div
            style={{
              width: 'calc(100% + 2rem)',
              margin: '-1rem',
            }}
          >
            <iframe
              title="docs"
              src={`https://preven.docs.concordia.dev.br/${path}`}
              width="100%"
              style={{
                marginBottom: '-6px',
                border: 0,
                height: 'calc(100vh - 130px)',
              }}
            />
          </div>
        ),
      })
    },
    [showModal]
  )

  return showDocs
}

export default useDocumentationModal
