import { combineReducers } from 'redux'
import * as types from './types'

export const parseJson = (value) => {
  if (value)
    try {
      return JSON.parse(value)
    } catch (error) {}
  return null
}

const listReducer = (
  state = parseJson(localStorage.getItem(types.LIST)),
  action
) => {
  switch (action.type) {
    case types.LIST:
      localStorage.setItem(types.LIST, JSON.stringify(action.payload))
      return action.payload

    default:
      return state
  }
}

const selectedReducer = (
  state = parseJson(localStorage.getItem(types.SELECT)),
  action
) => {
  switch (action.type) {
    case types.SELECT:
      if (action.payload)
        localStorage.setItem(types.SELECT, JSON.stringify(action.payload))
      
      return action.payload || null

    default:
      return state || null
  }
}

const reducer = combineReducers({
  list: listReducer,
  selected: selectedReducer,
})

export default reducer
