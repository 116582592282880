import { loaderOperations } from 'src/state/ducks/loader'
import AuthApi from './authApi'
import BasePrevenApiClient from './baseApi'

export const BASE_URL = process.env.REACT_APP_DJANGO_BACKEND_URL
export const BASE_PATH = process.env.REACT_APP_DJANGO_BACKEND_PATH || ''

class ReportApi extends BasePrevenApiClient {
  /**
   * Returns a shared api instance
   *
   * @returns {ReportApi} Api shared instance
   */
  static get shared() {
    return super.shared
  }

  /**
   * Format an object into querystring parameters
   *
   * @param {object} params Querystring parameters
   */
  _formatQuerystring(params) {
    return Object.entries(params)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => {
        if (Array.isArray(value))
          return value.map((v) => `${key}=${v}`).join('&')
        else if (typeof value === 'boolean') value = value ? 'True' : 'False'

        return `${key}=${encodeURI(value)}`
      })
      .join('&')
  }

  /**
   * List the list reports alternatives
   */
  getListReportAlternatives(authToken) {
    const path = this._getFullPath('/reports/list-reports')

    return this._get(path, { authToken })
  }

  /**
   * Gets the report filter form for the specific model
   *
   * @param {string} authToken User's authorization bearer
   * @param {string} djangoApp Django app name
   * @param {string} djangoModel Django model name
   */
  getReportForm = (authToken, djangoApp, djangoModel) => {
    const path = this._getFullPath(`/${djangoApp}/report/form/${djangoModel}`)

    this._dispatch(loaderOperations.start(path))
    return this._get(path, { authToken }).then(
      (data) => {
        this._dispatch(loaderOperations.stop(path))
        return data
      },
      (error) => {
        this._dispatch(loaderOperations.stop(path))
        this._clearMemos()
        throw error
      }
    )
  }

  /**
   * Gets the report url for the specific model
   *
   * @param {string} djangoApp Django app name
   * @param {string} djangoModel Django model name
   * @param {object} params Querystring parameters
   */
  getReportUrl(
    djangoApp,
    djangoModel,
    params,
    format = null,
    variant = 'list'
  ) {
    let initialPath = `/${djangoApp}/report/${variant}/${djangoModel}`
    if (format) initialPath += `/${format}`

    let path = this._getFullPath(initialPath)
    const querystring = this._formatQuerystring({
      ...(params || {}),
      company: this.companyHeader,
    })

    if (querystring) {
      path = path + `?${querystring}`
    }
    return path
  }

  openReportUrl(token, ...params) {
    AuthApi.shared.handleSessionPersistance(
      token,
      this.getReportUrl(...params),
      true
    )
  }

  /**
   * Gets the report as json data for the specific model
   *
   * @param {string} djangoApp Django app name
   * @param {string} djangoModel Django model name
   * @param {object} params Querystring parameters
   */
  getReportData(djangoApp, djangoModel, params) {
    return this._get(this.getReportUrl(djangoApp, djangoModel, params, 'json'))
  }

  /**
   * Gets the report csv url for the specific model
   *
   * @param {string} djangoApp Django app name
   * @param {string} djangoModel Django model name
   * @param {object} params Querystring parameters
   */
  getReportCsvUrl(djangoApp, djangoModel, params) {
    return this._getFullPath(
      `/${djangoApp}/report/list/${djangoModel}/csv?${this._formatQuerystring(
        params
      )}`
    )
  }

  _fileDownload(token, url, fileName, method = 'GET', config = {}) {
    this._dispatch(loaderOperations.start('file-download'))
    return this._request(method, {
      url,
      timeout: 60000,
      responseType: 'blob',
      authToken: token,
      ...config,
    })
      .then(({ data }) => URL.createObjectURL(data))
      .then((url) => {
        this._dispatch(loaderOperations.stop('file-download'))
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        return url
      })
      .catch((e) => {
        this._dispatch(loaderOperations.stop('file-download'))
        throw e
      })
  }
}

export default ReportApi
