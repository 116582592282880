import PropTypes from 'prop-types'

export interface DjangoRemoteFormFieldWidget {
  attrs: object
  input_type: String
  is_hidden: Boolean
  is_localized: Boolean
  is_required: Boolean
  needs_multipart_form: Boolean
  title: String
}

export interface DjangoRemoteFormFields {
  error_messages: object
  help_text: String
  initial: PropTypes.any
  label: String
  max_length: PropTypes.number
  min_length: PropTypes.number
  required: PropTypes.bool
  title: String
  widget: DjangoRemoteFormFieldWidget
}

export interface DjangoRemoteForm {
  data: object
  errors: object
  fields: object
  fieldsets: any[]
  is_bound: Boolean
  label_sufix: String
  non_field_errors: any[]
  ordered_fields: String[]
  prefix: PropTypes.any
  title: String
}

export const DjangoRemoteFormFieldWidgetPropTypes = PropTypes.shape({
  attrs: PropTypes.object,
  input_type: PropTypes.string,
  is_hidden: PropTypes.bool,
  is_localized: PropTypes.bool,
  is_required: PropTypes.bool,
  needs_multipart_form: PropTypes.bool,
  title: PropTypes.string,
})

export const DjangoRemoteFormFieldPropTypes = PropTypes.shape({
  error_messages: PropTypes.object,
  help_text: PropTypes.string,
  initial: PropTypes.any,
  label: PropTypes.string,
  max_length: PropTypes.number,
  min_length: PropTypes.number,
  required: PropTypes.bool,
  title: PropTypes.string,
  widget: DjangoRemoteFormFieldWidgetPropTypes,
})

export const DjangoRemoteFormPropTypes = PropTypes.shape({
  data: PropTypes.object,
  errors: PropTypes.object,
  fields: PropTypes.arrayOf(DjangoRemoteFormFieldPropTypes),
  fieldsets: PropTypes.arrayOf(PropTypes.any),
  is_bound: PropTypes.bool,
  label_sufix: PropTypes.string,
  non_field_errors: PropTypes.arrayOf(PropTypes.any),
  ordered_fields: PropTypes.arrayOf(PropTypes.string),
  prefix: PropTypes.any,
  title: PropTypes.string,
})
