import React, { Component } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { modalOperations } from 'src/state/ducks/modal'
import { isMobile } from 'src/reusable/DjangoList'

class ModalComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstConfirmation: false,
    }
  }

  handleCancel = () => {
    const { onCancel, closeModal } = this.props

    closeModal()
    onCancel && onCancel()
    this.setState({ firstConfirmation: false })
  }

  handleConfirmation = () => {
    const {
      onConfirm,
      doubleClickToConfirm, // TODO: Maybe use another color for this cases, maybe red
      closeModal,
      closeOnConfirmation = true,
    } = this.props
    const { firstConfirmation } = this.state

    if (doubleClickToConfirm && !firstConfirmation) {
      this.setState({ firstConfirmation: true })
      return
    }

    closeOnConfirmation && closeModal()
    onConfirm && onConfirm({ state: { ...this.state }, close: closeModal })
    this.setState({ firstConfirmation: false })
  }

  render() {
    const {
      title,
      body,
      size,
      className,
      onCancel,
      cancelButtonLabel,
      onConfirm,
      confirmButtonLabel,
      doubleClickToConfirm,
      confirmSecondLabel,
      isOpen,
      closeOnBackdrop = true,
    } = this.props
    const { firstConfirmation } = this.state

    const confirmLabel = confirmButtonLabel || 'Confirmar'
    const confirmSecLabel = confirmSecondLabel || 'Clique novamente'
    const footerContent = [
      onCancel !== null && (
        <>
          <CButton
            block={isMobile.any()}
            color="secondary"
            variant="outline"
            onClick={this.handleCancel}
          >
            {cancelButtonLabel || 'Cancelar'}
          </CButton>
        </>
      ),
      onConfirm !== null && (
        <>
          <CButton
            block={isMobile.any()}
            onClick={this.handleConfirmation}
            color="primary"
          >
            {doubleClickToConfirm
              ? firstConfirmation
                ? confirmSecLabel
                : confirmLabel
              : confirmLabel}
          </CButton>
        </>
      ),
    ].filter(Boolean)
    if (isMobile.any()) footerContent.reverse()

    return (
      <CModal
        show={isOpen}
        onClose={this.handleCancel}
        size={size || ''}
        className={className}
        closeOnBackdrop={closeOnBackdrop}
      >
        {title && (
          <CModalHeader closeButton>
            <CModalTitle>{title}</CModalTitle>
          </CModalHeader>
        )}
        <CModalBody>
          {typeof body === 'function'
            ? body({
                setState: (newValue) => this.setState(newValue),
                state: { ...this.state },
              })
            : body}
        </CModalBody>

        {!!footerContent.length && <CModalFooter>{footerContent}</CModalFooter>}
      </CModal>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeModal: modalOperations.dismiss }, dispatch)
}

export default connect(null, mapDispatchToProps)(ModalComponent)
