import React, { useCallback, useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import chroma from 'chroma-js'
import GenericInputGroup from './GenericInputGroup'
import { validateFieldValue } from './utils'
import ModelSelect from 'src/components/ModelSelect/ModelSelect'
import { ActionButton } from 'src/views/buttons'
import ReportApi from 'src/api/reportApi'
import { useToken } from 'src/state/ducks/auth/hooks'
import { useModalForm } from 'src/state/ducks/modalForm/hooks'
import { CBadge } from '@coreui/react'
import { parseReportFilterForm } from '../DjangoList'
import { useToast } from 'src/state/ducks/toast/hooks'

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    if (data.color && data.color !== '#FFF') {
      const color = chroma(data.color)
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      }
    } else {
      return { ...styles }
    }
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}

const SelectInput = ({
  objectId,
  fieldName,
  fieldData,
  fieldErrors,
  value,
  onChange,
  submited,
  onRelationChange,
  disabled = false,
}) => {
  const { app_name: djangoApp, model_name: djangoModel } = fieldData
  const choices = fieldData.widget.choices || []
  const attrs = fieldData.widget.attrs || {}

  const selectRef = useRef()
  const authToken = useToken()
  const showModalForm = useModalForm()
  const showToast = useToast()

  const initialModelFilters = useMemo(() => {
    try {
      return JSON.parse(attrs.modelfilter) || {}
    } catch (error) {
      return {}
    }
  }, [attrs])
  const [modelFilters, setModelFilters] = useState(initialModelFilters)

  const options = choices.map(
    ({ value: optionValue, display, ...extraOpts }) => ({
      ...extraOpts,
      value: optionValue,
      label: display,
    })
  )
  const isMulti = fieldData.widget.input_type === 'selectmultiple'
  const selectedOption = isMulti
    ? options.filter(
        (opt) => (value || fieldData.initial || []).indexOf(opt.value) > -1
      )
    : options.find((opt) => opt.value === value)

  const handleChange = isMulti
    ? (options) => {
        const value = (options || []).map(({ value }) => value)
        onChange(value, validateFieldValue(fieldData, value))
      }
    : ({ value }) => onChange(value, validateFieldValue(fieldData, value))

  const handleReportFilter = useCallback(() => {
    ReportApi.shared
      .getReportForm(authToken, djangoApp, djangoModel)
      .then(({ data }) =>
        showModalForm(
          null,
          null,
          null,
          null,
          null,
          (filters) =>
            setModelFilters(parseReportFilterForm(filters, data.form)),
          () => showToast('Erro ao buscar os filtros', { appearance: 'error' }),
          {
            form: data.form,
            showRelatedFieldsActions: false,
          }
        )
      )
  }, [authToken, djangoApp, djangoModel, showToast, showModalForm])
  disabled = disabled || attrs.disabled || attrs.readonly

  return (
    <GenericInputGroup
      fieldName={fieldName}
      fieldData={fieldData}
      fieldErrors={fieldErrors}
      value={value}
      submited={submited}
    >
      <div
        className={`select-row ${
          (fieldErrors || []).length > 0 ? 'is-invalid' : ''
        }`}
      >
        {djangoApp && djangoModel ? (
          <ModelSelect
            ref={selectRef}
            id={`field-${fieldName}`}
            authToken={authToken}
            fieldName={fieldName}
            djangoApp={djangoApp}
            djangoModel={djangoModel}
            onChange={handleChange}
            value={isMulti ? value || fieldData.initial : value}
            options={options.slice(0, 10)}
            isDisabled={disabled}
            styles={colourStyles}
            isMulti={isMulti}
            modelFilter={
              modelFilters
                ? JSON.stringify(modelFilters)
                : attrs.modelfilter || '{}'
            }
          />
        ) : (
          <Select
            id={`field-${fieldName}`}
            className="select__basic-single"
            classNamePrefix="select"
            placeholder="Selecione uma opção"
            noOptionsMessage={() => 'Nenhuma opção encontrada'}
            loadingMessage={() => 'Carregando...'}
            value={selectedOption}
            isSearchable={true}
            name={fieldName}
            isDisabled={disabled}
            options={options}
            onChange={handleChange}
            styles={colourStyles}
            isMulti={isMulti}
          />
        )}
        {!!onRelationChange && !disabled && (
          <>
            {!!objectId && !!djangoModel && !!value && !isMulti && (
              <ActionButton
                onClick={() => onRelationChange(djangoApp, djangoModel, value)}
                iconName="cil-pencil"
                label="Editar"
              />
            )}
            {!!djangoModel && (
              <>
                {/* TODO: Open to other models */}
                {['equipment'].includes(djangoModel) && (
                  <ActionButton
                    onClick={handleReportFilter}
                    iconName="cil-filter"
                    label="Filtrar"
                  />
                )}
                <ActionButton
                  onClick={() =>
                    onRelationChange(
                      djangoApp,
                      djangoModel,
                      undefined,
                      undefined,
                      undefined,
                      () => {
                        if (selectRef && selectRef.current) {
                          selectRef.current.forceUpdate()
                        }
                      }
                    )
                  }
                  iconName="cil-plus"
                  label="Novo"
                />
              </>
            )}
          </>
        )}
      </div>
      {Object.entries(modelFilters)
        .filter(
          ([key, value]) =>
            key !== 'company' && !!value && !initialModelFilters[key]
        )
        .map(([key, value]) => (
          <CBadge color="secondary" size="sm" style={{ marginRight: '.5rem' }}>
            {key.replace('_id', '').translate().toTitleCase()}:{' '}
            {JSON.stringify(value)}
          </CBadge>
        ))}
    </GenericInputGroup>
  )
}

export default SelectInput
